import React from 'react';
import PropTypes from 'prop-types';
import {Card} from 'uui_kit';
import Grid from 'src/components/Grid';
import Image from 'src/components/Image';
import LaunchPanel from 'src/components/productTile/LaunchPanel';
import ContactPanel from 'src/components/productTile/ContactPanel';
import ComingSoonPanel from 'src/components/productTile/ComingSoonPanel';
import vectorSolutionsIcon from 'src/assets/VectorSolutionsIcon.png';

function ProductMockedLogo({name, iconUrl}) {
  return (
    <div className="product-mocked-logo">
      <Image
        className="product-icon"
        src={iconUrl}
        alt={`${name} icon`}
      >
        <img
          className="product-icon"
          src={vectorSolutionsIcon}
          alt={`${name} icon`}
        />
      </Image>
      <h4 className="product-name">{name}</h4>
    </div>
  );
}

function ProductLogo({logoUrl, iconUrl, name}) {
  return (
    <Image className="product-original-logo" src={logoUrl}>
      <ProductMockedLogo iconUrl={iconUrl} name={name} />
    </Image>
  );
}

export default class ProductTile extends React.PureComponent {

  static propTypes = {
    name: PropTypes.string,
    description: PropTypes.string,
    logoUrl: PropTypes.string,
    iconUrl: PropTypes.string,
    licensed: PropTypes.bool,
    comingSoon: PropTypes.bool,
    entryPointUrl: PropTypes.string,
    signInUrl: PropTypes.string,
    freeTrialUrl: PropTypes.string,
    contactUrl: PropTypes.string,
    detailUrl: PropTypes.string,
  }

  static defaultProps = {
    licensed: false,
    comingSoon: false
  }

  render() {
    const {name, description, logoUrl, iconUrl, licensed, comingSoon, ...rest} = this.props;

    return (
      <Card className="product-tile">
        <Grid container className="header">
          <Grid xs={12}>
            <ProductLogo
              logoUrl={logoUrl}
              iconUrl={iconUrl}
              name={name}
            />
          </Grid>
        </Grid>

        <Grid container className="description">
          <Grid xs={12}>
            <p className="p">
              <span className="small">{description}</span>
            </p>
          </Grid>
        </Grid>

        {licensed && !comingSoon && (
          <LaunchPanel {...rest} />
        )}

        {!licensed && !comingSoon && (
          <ContactPanel {...rest} />
        )}

        {comingSoon && (
          <ComingSoonPanel />
        )}
      </Card>
    );
  }
}
