import RecommendedActionType from 'src/modules/recommended/RecommendedActionType';

const initialState = {
  loading: false,
  products: [],
  error: false
};

export default function(state = initialState, action) {
  switch(action.type) {
    case RecommendedActionType.LOAD_PRODUCTS_REQUEST:
      return {
        ...state,
        ...initialState,
        loading: true
      };

    case RecommendedActionType.LOAD_PRODUCTS_SUCCESS:
      return {
        ...state,
        loading: false,
        products: action.payload
      };

    case RecommendedActionType.LOAD_PRODUCTS_FAIL:
      return {
        ...state,
        loading: false,
        error: true
      };

    case RecommendedActionType.RESET:
      return {
        ...initialState
      };

    default: return state;
  }
};
