import {forever} from 'src/util/promise';

let initialSitePath = null;

export const appPath = process.env.PUBLIC_URL.replace(/^\//, '');

export function storeSitePath(path) {
  initialSitePath = path;
}

export async function changeSitePath(tenantSitePath = '') {
  if (!initialSitePath && !tenantSitePath) {
    return;
  }

  if (initialSitePath === tenantSitePath) {
    return;
  }

  const {location} = window;

  const newBasePath = getBasePath(tenantSitePath);
  const path = location.pathname.split(getBasePath())[1];

  setTimeout(() => {
    location.pathname = `${newBasePath}${path || ''}`;
  }, 1000);

  return forever();
}

export function getSitePath() {
  return initialSitePath;
}

export function getBasePath(sitePath = initialSitePath) {
  if (!sitePath) {
    return `/${appPath}`;
  }

  return `/${sitePath}/${appPath}`;
}

export function getBaseUrl(sitePath = initialSitePath) {
  const {protocol, port, hostname} = window.location;
  let redirectUrl = `${protocol}//${hostname}`;

  if (port) {
    redirectUrl += `:${port}`;
  }

  const basePath = getBasePath(sitePath);

  if (basePath) {
    redirectUrl += basePath;
  }

  return redirectUrl;
}
