export default class SecurityRole {
  static USER = 'USER'
  static TENANT_ADMIN = 'TENANT_ADMIN'
  static ADMIN = 'ADMIN'

  static exists(roleName) {
    if (!roleName) {
      return false;
    }

    return typeof(SecurityRole[roleName.toUpperCase()]) === 'string';
  }

  static all() {
    return [
      SecurityRole.USER,
      SecurityRole.TENANT_ADMIN,
      SecurityRole.ADMIN
    ];
  }

};
