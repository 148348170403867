export default class BusinessError extends Error {

  constructor(message) {
    super(message);
    this.name = 'BusinessError';
  }

  get isBusiness() {
    return true;
  }
}
