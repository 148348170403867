import qs from 'qs';
import isEmpty from 'lodash/isEmpty';

export default class UrlBuilder {

  url = null
  urlParams = {}
  hashParams = {}

  constructor(url) {
    const parsedUrl = new URL(url);

    this.url = parsedUrl;
    this.urlParams = qs.parse(parsedUrl.search, {ignoreQueryPrefix: true});
    this.hashParams = qs.parse(parsedUrl.hash.replace(/^#/, ''));
  }

  addUrlParam(name, value) {
    this.urlParams[name] = value;
    return this;
  }

  addHashParam(name, value) {
    this.hashParams[name] = value;
    return this;
  }

  toString() {
    const {url, hashParams, urlParams} = this;

    url.search = isEmpty(urlParams) ? '' : `?${qs.stringify(urlParams)}`;
    url.hash = isEmpty(hashParams) ? '' : `#${qs.stringify(hashParams)}`;

    return url.toString();
  }

}
