import config from 'src/config';
import SecurityActionType from 'src/security/SecurityActionType';
import authenticationService from 'src/services/AuthenticationService';
import securityService from 'src/services/SecurityService';
import currentUserService from 'src/services/CurrentUserService';
import currentTenantService from 'src/services/CurrentTenantService';
import {changeSitePath} from 'src/route/routing';
import featureFlagService from 'src/services/FeatureFlagService';

export const redirectAuthentication = () => dispatch => {
  dispatch({
    type: SecurityActionType.PROCESSING_INTERNAL_AUTHENTICATION
  });

  authenticationService.redirectAuthentication();
};

export const processInternalAuthentication = () => async dispatch => {
  dispatch({
    type: SecurityActionType.PROCESSING_INTERNAL_AUTHENTICATION
  });

  try {
    const user = await currentUserService.get({throwError: true});
    const tenant = await currentTenantService.get();

    await changeSitePath(tenant && tenant.sitePath);

    await featureFlagService.initialize();

    const availableTenants = await currentTenantService.getAvailable();

    dispatch({
      type: SecurityActionType.INTERNAL_AUTHENTICATION_PROCESSED,
      payload: {
        user,
        tenant,
        availableTenants
      }
    });
  } catch (error) {
    if (error.notAuthenticated) {
      dispatch({ type: SecurityActionType.NOT_AUTHENTICATED });
    } else {
      console.error(error);
      dispatch({ type: SecurityActionType.AUTHENTICATION_ERROR });
    }
  }
}

export const refreshLocalData = ({user: userData}) => async dispatch => {
  const user = await securityService.refreshUserData(userData);

  dispatch({
    type: SecurityActionType.LOCAL_REFRESH,
    payload: {
      user
    }
  });
}

export const processAuthenticationResponse = stateString => async dispatch => {
  dispatch({
    type: SecurityActionType.PROCESSING_AUTHENTICATION_RESPONSE
  });

  try {
    const previousState = securityService.retrieveState(stateString)

    dispatch({
      type: SecurityActionType.AUTHENTICATION_RESPONSE_PROCESSED,
      payload: {
        previousState
      }
    });
  } catch (error) {
    console.error(error);

    dispatch({
      type: SecurityActionType.AUTHENTICATION_ERROR
    });
  }
};

export const processAuthenticationError = (error, errorDescription) => dispatch => {
  if (error) {
    console.group(`%cAuthentication error: ${error}`, 'color: red;');
    console.error('Type:', error);
    console.error('Description:', errorDescription);
    console.groupEnd();
  }

  dispatch({
    type: SecurityActionType.AUTHENTICATION_ERROR
  });
};

export const switchTenant = tenant => async dispatch => {
  try {
    dispatch({
      type: SecurityActionType.SWITCH_TENANT_REQUEST
    });
  
    await securityService.switchTenant(tenant);
  
    dispatch({
      type: SecurityActionType.SWITCH_TENANT_SUCCESS
    });
  } catch (error) {
    console.error(error);
    
    dispatch({
      type: SecurityActionType.SWITCH_TENANT_ERROR
    });

    return;
  }

  processInternalAuthentication()(dispatch);
};

export const checkSessionExpiration = () => async dispatch => {
  dispatch({
    type: SecurityActionType.SESSION_CHECK_START
  });

  try {
    const sessionTimeLeft = securityService.getSessionTimeLeft();
  
    if (!sessionTimeLeft || sessionTimeLeft <= 0) {
      dispatch(logout());
      return null;
    }

    const thresholdSeconds = config.session.timeoutConfirmationThreshold * 60;

    if (sessionTimeLeft <= thresholdSeconds) {
      dispatch({
        type: SecurityActionType.SESSION_NEAR_END
      });

      return sessionTimeLeft;
    }

    dispatch({
      type: SecurityActionType.SESSION_OK
    });
    
    return sessionTimeLeft - thresholdSeconds;
  } catch (error) {
    console.error(error);

    dispatch({
      type: SecurityActionType.SESSION_CHECK_FAIL
    });
  }
};

export const refreshSession = () => async dispatch => {
  dispatch({
    type: SecurityActionType.SESSION_REFRESH_REQUEST
  });

  try {
    await securityService.refreshSession();

    dispatch({
      type: SecurityActionType.SESSION_REFRESH_SUCCESS
    });
  } catch (error) {
    console.error(error);

    dispatch({
      type: SecurityActionType.SESSION_REFRESH_ERROR
    });
  }
};

export const logout = () => {
  securityService.logout();

  return {
    type: SecurityActionType.PROCESSING_INTERNAL_AUTHENTICATION
  };
};
