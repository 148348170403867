export const SAVE_USER_REQUEST = 'USER_PROFILE/SAVE_USER_REQUEST';
export const SAVE_USER_SUCCESS = 'USER_PROFILE/SAVE_USER_SUCCESS';
export const SAVE_USER_ERROR = 'USER_PROFILE/SAVE_USER_ERROR';

export const RESET = 'USER_PROFILE/RESET';

export default {
  SAVE_USER_REQUEST,
  SAVE_USER_SUCCESS,
  SAVE_USER_ERROR,
  
  RESET
};
