import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import cleanProps from 'clean-react-props';
import {Avatar as UiKitAvatar} from 'uui_kit';

const AVAILABLE_COLORS = [
  '#d73d32',
  '#7e3794',
  '#4285f4',
  '#67ae3f',
  '#d61a7f',
  '#ff4080'
];

function generateColor(name, colors = AVAILABLE_COLORS) {
    if (!name) {
      return 'transparent';
    }

    const colorsCount = colors.length;

    const charCodes = [...name].map(letter => letter.charCodeAt(0));
    const len = charCodes.length;

    const a = (len % (colorsCount - 1)) + 1;
    const c = charCodes.reduce((current, next) => current + next) % colorsCount;

    let random = charCodes[0] % colorsCount;
    for (let i = 0; i < len; i++) {
      random = ((a * random) + c) % colorsCount;
    }

    return colors[random] || 'transparent';
}

function withGeneratedColor(name) {
  return class GeneratedAvatarColor extends React.PureComponent {
    
    static displayName = `GeneratedAvatarColor${name}`;

    color = generateColor(name);

    renderChildren() {
      const {color} = this;

      return React.Children.map(this.props.children, child => {
        if (!child || !child.props || child.props.className !== 'avatar') {
          return child;
        }
  
        return React.cloneElement(child, {
          className: classNames(child.props.className, 'avatar-generated-color'),
          style: {
            ...child.props.style,
            backgroundColor: color,
            borderColor: color
          }
        });
      });
    }
    
    render() {
      
  
      return (
        <span {...cleanProps(this.props)}>
          {this.renderChildren()}
        </span>
      );
    }
  }
}

UiKitAvatar.propTypes.component = PropTypes.elementType; //eslint-disable-line react/forbid-foreign-prop-types
UiKitAvatar.propTypes.size = PropTypes.oneOf([ //eslint-disable-line react/forbid-foreign-prop-types
  'xsmall',
  'small',
  'medium',
  'large',
  'xlarge',
  'xxlarge',
  null,
]);

export default class Avatar extends React.PureComponent {

  static propTypes = {
      ...UiKitAvatar.propTypes,
      name: UiKitAvatar.propTypes.person,
      generateColor: PropTypes.bool
  }

  static defaultProps = {
    ...UiKitAvatar.defaultProps,
    generateColor: false
  }

  getActualName() {
    const {person, name} = this.props;

    const actualName = person || name;

    if (!actualName) {
      return null;
    }

    const parts = actualName.split(/\s+/g);

    if (parts.length <= 1) {
      return parts[0] || '';
    }

    return `${parts[0]} ${parts[1]}`;
  }

  render() {
    const {generateColor, person, name, ...props} = this.props;

    const actualName = this.getActualName();

    let Component = null;

    if (generateColor && actualName) {
      Component = withGeneratedColor(actualName);
    }

    return (
      <UiKitAvatar
        {...props}
        person={actualName}
        component={Component}
      />
    );
  }
}
