import {useState, useEffect} from 'react';
import featureFlagService from 'src/services/FeatureFlagService';

export default function useFeatureFlag(featureFlag) {
  const [flagValue, setFlagValue] = useState(featureFlag.defaultValue ?? false);

  useEffect(() => {
    featureFlagService.get(featureFlag).then(actualValue => {
      setFlagValue(actualValue);
    });
  }, [featureFlag]);

  return flagValue;
}