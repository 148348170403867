import licenseService from 'src/services/LicenseService';
import LicensedActionType from 'src/modules/licensed/LicensedActionType';

export const loadProducts = () => async dispatch => {
  dispatch({
    type: LicensedActionType.LOAD_PRODUCTS_REQUEST
  });

  try {
    const licensedProducts = await licenseService.getProducts();
    
    dispatch({
      type: LicensedActionType.LOAD_PRODUCTS_SUCCESS,
      payload: licensedProducts.filter(product => product.userEntitled)
    });
  } catch (error) {
    console.error(error);

    dispatch({
      type: LicensedActionType.LOAD_PRODUCTS_FAIL
    });
  }
};

export function reset() {
  return {
    type: LicensedActionType.RESET
  };
}