import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import cleanProps from 'clean-react-props';
import {FormLabel} from 'uui_kit';

function InputGroup(props) {
  const {children} = props;

  return (
    <span className="input-group">
      {children}
    </span>
  );
}

export default class TextInput extends React.PureComponent {
  
  static propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    autoComplete: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    readOnly: PropTypes.bool,
    disabled: PropTypes.bool,
    alignRight: PropTypes.bool,
    error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    autoFocus: PropTypes.bool,
    startAdornment: PropTypes.string,
    className: PropTypes.string,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    inputClassName: PropTypes.string,
    inputProps: PropTypes.object
  }

  render() {
    const {
      id,
      name,
      type,
      autoComplete,
      label,
      placeholder,
      value,
      readOnly,
      disabled,
      alignRight,
      error,
      startAdornment,
      className,
      onChange,
      onFocus,
      onBlur,
      inputClassName,
      inputProps
    } = this.props;

    const idOrName = id || name;

    const InputContainer = startAdornment ? InputGroup : React.Fragment;

    return (
      <div
        className={classNames(
          'form-group u-pos-relative',
          error && 'has-error',
          className
        )}
      >
        {label && (
          <FormLabel
            htmlFor={idOrName}
            label={label}
          />
        )}

        <InputContainer>
          {startAdornment && (
            <span className="input-group-addon">{startAdornment}</span>
          )}

          <input
            {...cleanProps(inputProps)}
            id={idOrName}
            name={idOrName}
            aria-label={label}
            type={type}
            autoComplete={autoComplete}
            value={value}
            readOnly={readOnly}
            disabled={disabled}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            placeholder={placeholder || label}
            className={classNames(
              'form-control u-mb-0',
              alignRight && 'u-text-right',
              inputClassName
            )}
          />
        </InputContainer>

        {error && typeof(error) === 'string' && (
          <span className="help-block" role="alert">
            {error}
          </span>
        )}
      </div>
    );
  }
}
