import productService from 'src/services/ProductService';
import DiscoverActionType from 'src/modules/discover/DiscoverActionType';

export const loadProducts = () => async dispatch => {
  dispatch({
    type: DiscoverActionType.LOAD_PRODUCTS_REQUEST
  });

  try {
    const allProducts = await productService.getAvailable();
    
    dispatch({
      type: DiscoverActionType.LOAD_PRODUCTS_SUCCESS,
      payload: allProducts
    });
  } catch (error) {
    console.error(error);

    dispatch({
      type: DiscoverActionType.LOAD_PRODUCTS_FAIL
    });
  }
};

export function reset() {
  return {
    type: DiscoverActionType.RESET
  };
}
