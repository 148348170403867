import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import some from 'lodash/some';
import { Card, Alert, Heading } from 'uui_kit';
import Page from 'src/components/Page';
import Grid from 'src/components/Grid';
import Button from 'src/components/Button';
import TextInput from 'src/components/TextInput';
import LoadingSpinner from 'src/components/LoadingSpinner';
import { useError } from 'src/util/hooks';
import { saveUser, reset } from 'src/modules/user/profile/UserProfileActions';

const US_COUNTRY_CODE_REGEX = /^\+\d/;
const NON_DIGIT_REGEX = /\D+/g;
const VALID_PHONE_REGEX = /^\d{6,14}$/;

function checkInvalidName(value, label = 'name') {
  if (!value) {
    return `The ${label} is required.`;
  }

  if (value.length > 100) {
    return `The ${label} can only have up to 100 characters.`;
  }

  return false;
}

function checkInvalidPhoneNumber(number) {
  if (!number) {
    return false;
  }

  if (!VALID_PHONE_REGEX.test(number)) {
    return 'Invalid phone number';
  }

  return false;
}

export default function UserProfile() {
  const dispatch = useDispatch();

  const {
    saving,
    success,
    error,
    errorMessage
  } = useSelector(state => get(state, 'modules.user.profile', {}));

  const mobile = useSelector(state => get(state, 'device.type.mobile', false));
  const user = useSelector(state => get(state, 'security.user'));

  const { email } = user;

  const [firstName, setFirstName] = useState('');
  const [firstNameError, setFirstNameError] = useError(false);

  const [lastName, setLastName] = useState('');
  const [lastNameError, setLastNameError] = useError(false);

  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneNumberError, setPhoneNumberError] = useError(false);

  const nationalPhoneNumber = useMemo(() => {
    if (!user.phoneNumber) {
      return '';
    }

    return user.phoneNumber.replace(US_COUNTRY_CODE_REGEX, '');
  }, [user.phoneNumber]);

  const changed = useMemo(() => {
    const originalUser = {
      ...user,
      phoneNumber: nationalPhoneNumber
    };

    const currentUser = {
      firstName,
      lastName,
      phoneNumber
    };

    return some(currentUser, (newValue, propName) => {
      const oldValue = originalUser[propName];

      if (!oldValue && !newValue) {
        return false;
      }

      return oldValue !== newValue;
    });
  }, [user, nationalPhoneNumber, firstName, lastName, phoneNumber])

  const checkFirstNameError = useCallback((value = firstName, immediate = true) => {
    const error = checkInvalidName(value, 'first name');
    setFirstNameError(error, immediate);

    return error;
  }, [firstName, setFirstNameError]);

  const checkLastNameError = useCallback((value = lastName, immediate = true) => {
    const error = checkInvalidName(value, 'last name');
    setLastNameError(error, immediate);

    return error;
  }, [lastName, setLastNameError]);

  const checkPhoneNumberError = useCallback((value = phoneNumber, immediate = true) => {
    const error = checkInvalidPhoneNumber(value);
    setPhoneNumberError(error, immediate);

    return error;
  }, [phoneNumber, setPhoneNumberError]);

  const handleFirstNameChange = useCallback(event => {
    const { value } = event.target;

    setFirstName(value);
    checkFirstNameError(value, false);
  }, [setFirstName, checkFirstNameError]);

  const handleLastNameChange = useCallback(event => {
    const { value } = event.target;

    setLastName(value);
    checkLastNameError(value, false);
  }, [setLastName, checkLastNameError]);

  const handlePhoneNumberChange = useCallback(event => {
    const { value } = event.target;

    const phoneNumber = value ? value.replace(NON_DIGIT_REGEX, '') : '';
    setPhoneNumber(phoneNumber);
    checkPhoneNumberError(phoneNumber, false);
  }, [setPhoneNumber, checkPhoneNumberError]);

  const handleClear = useCallback(() => {
    setFirstName(user.firstName);
    setFirstNameError(false);

    setLastName(user.lastName);
    setLastNameError(false);

    const phoneNumber = user.phoneNumber ? user.phoneNumber.replace(US_COUNTRY_CODE_REGEX, '') : '';
    setPhoneNumber(phoneNumber);
    setPhoneNumberError(false);
  }, [user, setFirstName, setFirstNameError, setLastName, setLastNameError, setPhoneNumber, setPhoneNumberError]);

  const handleSave = useCallback(event => {
    event.preventDefault();

    const hasError = [
      checkFirstNameError(), checkLastNameError(), checkPhoneNumberError()
    ].some(error => Boolean(error));

    if (hasError) {
      return;
    }

    dispatch(saveUser({
      firstName,
      lastName,
      phoneNumber: `+1${phoneNumber}`
    }))
  }, [firstName, lastName, phoneNumber, checkFirstNameError, checkLastNameError, checkPhoneNumberError, dispatch]);

  useEffect(() => {
    handleClear();
  }, [handleClear]);

  useEffect(() => () => {
    dispatch(reset());
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Page title="My Profile" className="user-profile">
      <Card>
        <form
          name="changePasswordForm"
          className="user-profile-container"
          onSubmit={handleSave}
        >
          <Grid container direction="column" justify="start" align="center">
            <Grid item xs={12}>
              {success && (
                <Alert variant="confirm">
                  Your profile was updated successfully.
                </Alert>
              )}

              {error && (
                <Alert variant={errorMessage ? 'warning' : 'danger'}>
                  {errorMessage || 'An error happened while updating your password.'}
                </Alert>
              )}
            </Grid>
            <Grid
              container
              xs={12}
              sm={8}
              xl={6}
              spacing={2}
              align="start"
            >
              <Grid xs={12}>
                <Heading
                  level="2"
                  appearance="2"
                  color="product"
                  bold
                  className="text-center"
                >
                  My Profile
                </Heading>
              </Grid>

              <Grid xs={11}>
                <TextInput
                  type="text"
                  label="Email Address"
                  autoComplete="email"
                  value={email}
                  disabled
                />
              </Grid>

              <Grid xs={11}>
                <TextInput
                  type="tel"
                  label="Phone Number"
                  startAdornment="+1"
                  autocomplete="tel-national"
                  disabled={saving}
                  error={phoneNumberError}
                  value={phoneNumber}
                  onChange={handlePhoneNumberChange}
                />
              </Grid>

              <Grid xs={11}>
                <TextInput
                  type="text"
                  label="First Name"
                  autocomplete="given-name"
                  disabled={saving}
                  error={firstNameError}
                  value={firstName}
                  onChange={handleFirstNameChange}
                />
              </Grid>

              <Grid xs={11}>
                <TextInput
                  type="text"
                  label="Last Name"
                  autocomplete="family-name"
                  disabled={saving}
                  error={lastNameError}
                  value={lastName}
                  onChange={handleLastNameChange}
                />
              </Grid>
            </Grid>

            <Grid
              container
              xs={12}
              sm={11}
              spacing={2}
              justify={mobile ? 'start' : 'end'}
            >
              <Grid xs={6} sm={4} md={2}>
                <Button
                  type="button"
                  block
                  variant="default"
                  text="Cancel"
                  disabled={!changed || saving}
                  onClick={handleClear}
                />
              </Grid>

              <Grid xs={5} sm={4} md={2}>
                <Button
                  type="submit"
                  block
                  variant="primary"
                  text="Save"
                  disabled={!changed || saving}
                  onClick={handleSave}
                >
                  {saving && (
                    <LoadingSpinner size={25} color="white" />
                  )}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Card>
    </Page>
  );
}
