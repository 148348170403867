import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import get from 'lodash/get';
import {Modal} from 'uui_kit';
import Button from 'src/components/Button';
import LoadingSpinner from 'src/components/LoadingSpinner';
import {refreshSession, logout} from 'src/security/SecurityActions'

class SessionTimeoutModal extends React.PureComponent {

  static propTypes = {
    onSessionRefreshed: PropTypes.func
  }

  static defaultProps = {
    onSessionRefreshed: () => {}
  }

  handleLogout = () => {
    this.props.logout();
  }

  handleRefreshSession = async () => {
    await this.props.refreshSession();
    this.props.onSessionRefreshed();
  }

  render() {
    const {nearEnd, refreshing, error} = this.props;
    
    return (
      <Modal show={nearEnd} className="session-timeout-modal">
        <Modal.Body>
          <h4>Your session is about to expire, would you like to remain signed in?</h4>
        </Modal.Body>

        <Modal.Footer>
          {error && (
            <Modal.FooterCell className="u-text-left">
              <span style={{color: 'red'}}>
                An error happened while trying to refresh your session. Please, try again.
              </span>
            </Modal.FooterCell>
          )}

          <Modal.FooterCell className="u-text-right">
            <Button
              variant="subtle"
              text="No"
              disabled={refreshing}
              onClick={this.handleLogout}
            />

            <Button
              variant="primary"
              text="Yes"
              disabled={refreshing}
              onClick={this.handleRefreshSession}
            >
              {refreshing && (
                <LoadingSpinner size={25} color="white" />
              )}
            </Button>
          </Modal.FooterCell>
        </Modal.Footer>
      </Modal>
    );
  }
}

const withRedux = connect(state => {
  const session = get(state, 'security.session', {});

  return {
    nearEnd: session.nearEnd,
    refreshing: session.refreshing,
    error: session.error
  };
}, {
  refreshSession,
  logout
});

export default withRedux(SessionTimeoutModal);

