import React from 'react';
import PropTypes from 'prop-types';
import {Helmet} from 'react-helmet';
import {PageWrapper} from 'uui_kit';

export default class Page extends React.PureComponent {

  static propTypes = {
    ...PageWrapper.propTypes,
    title: PropTypes.string
  };


  render() {
    const {title, ...rest} = this.props;

    return (
      <>
        {title && (
          <Helmet title={title} />
        )}
        
        <PageWrapper {...rest} />
      </>
    );
  }
}
