import axios from 'axios';
import qs from 'qs';
import defaultsDeep from 'lodash/defaultsDeep';
import config from 'src/config';
import authenticationService from 'src/services/AuthenticationService';

const UNAUTHENTICATED_STATUS = [401, 403];

function returnResponseData(response) {
  const {config} = response;

  if (config.rawResponse) {
    return response;
  }

  return response.data;
}

function handleError(error) {
  if (axios.isCancel(error)) {
    return Promise.reject({
      message: 'Request canceled'
    });
  }

  const {config, response} = error;

  if (!response) {
    return Promise.reject(error);
  }

  const notAuthenticated = UNAUTHENTICATED_STATUS.includes(response.status);

  if (config && config.skipAuthentication) {
    error.notAuthenticated = notAuthenticated;
    return Promise.reject(error);
  }

  if (notAuthenticated) {
    return authenticationService.redirectAuthentication();
  }

  return Promise.reject(error);
}

function serializeQueryParameters(params) {
  return qs.stringify(params, {
    arrayFormat: 'repeat'
  })
}

const DEFAULT_REQUEST_CONFIG = {
  withCredentials: true,
  paramsSerializer: serializeQueryParameters,
  headers: {
    'V-Idp-Client': config.auth.clientId
  }
}

export default class BaseApi {

  static create(config = {}) {
    let currentCancelSource = axios.CancelToken.source();

    const api = axios.create(defaultsDeep({}, config, DEFAULT_REQUEST_CONFIG));

    const addCancelToken = (config) => {
      config.cancelToken = currentCancelSource.token
      return config;
    };

    api.interceptors.request.use(addCancelToken);
    api.interceptors.response.use(returnResponseData, handleError);

    api.cancelOngoingRequests = () => {
      currentCancelSource.cancel();
      currentCancelSource = axios.CancelToken.source();
    };

    return api;
  }
  
}
