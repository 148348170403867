import tenantApi from 'src/services/TenantApi';
import currentTenantService from 'src/services/CurrentTenantService';
import currentUserService from 'src/services/CurrentUserService';
import SecurityRole from 'src/security/SecurityRole';
import {getTenantAdminAppUrl} from 'src/util/vidp';

export class LicenseService {

  async getProducts() {
    const currentTenant = await currentTenantService.get();
    const currentUser = await currentUserService.get();

    let licenseProducts = await tenantApi.get(`/tenants/${currentTenant.id}/users/${currentUser.id}/products`).catch(error => {
      const {response} = error;

      if (response && response.status === 404) {
        return [];
      }

      return Promise.reject(error);
    });

    const products = licenseProducts?.map(licenseProduct => ({
      ...licenseProduct.product,
      licensed: true,
      userEntitled: licenseProduct.enabled
    })) ?? [];

    products.push({
      id: 'tenant-admin',
      name: 'Administration',
      description: 'Access administration tools to manage your Vector Solutions applications and users.',
      entryPointUrl: getTenantAdminAppUrl(),
      roles: [SecurityRole.TENANT_ADMIN],
      licensed: true,
      userEntitled: true
    });

    return products;
  }

}

export default new LicenseService();
