import React from 'react';
import PropTypes from 'prop-types';
import Avatar from 'src/components/Avatar';

export default class UserMenuButton extends React.PureComponent {

  static propTypes = {
    name: PropTypes.string,
    onClick: PropTypes.func
  }

  static defaultProps = {
    name: '',
    onClick: () => {}
  }

  render() {
    const {name, onClick} = this.props;

    return (
      <button
        type="button"
        className="user-menu-button"
        onClick={onClick}
      >
        <table className="table-naked u-width-p-12" role="presentation">
          <tbody>
            <tr>
              <td className="user-name u-pos-vertical-align">
                <div className="user-name-text small u-color-secondary u-text-bold">
                  {name}
                </div>
              </td>
              <td className="avatar-column u-p-0 u-pos-vertical-align u-width-1">
                <Avatar name={name} />
              </td>
            </tr>
          </tbody>
        </table>
      </button>
    );
  }
}