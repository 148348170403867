import React from 'react';
import {connect} from 'react-redux';
import Page from 'src/components/Page';
import ProductListSection from 'src/components/ProductListSection';
import {loadProducts, reset} from 'src/modules/recommended/RecommendedActions';

class Recommended extends React.PureComponent {

  componentDidMount() {
    this.props.loadProducts();
  }

  componentWillUnmount() {
    this.props.reset();
  }

  render() {
    const {loading, products} = this.props.recommended;

    return (
      <Page title="Recommended for you">
        <ProductListSection
          title="Recommended for you"
          loading={loading}
          products={products}
        />
      </Page>
    )
  }
}

const withRedux = connect(state => {
  const {recommended} = state.modules;

  return {
    recommended
  };
}, {
  loadProducts,
  reset
});

export default withRedux(Recommended);
