import qs from 'qs';
import config from 'src/config';
import authGatewayApi from 'src/services/AuthGatewayApi';
import tenantApi from 'src/services/TenantApi';
import { getBaseUrl, changeSitePath } from 'src/route/routing';
import { getCookieValue } from 'src/util/cookie';

function getUser(userInfo) {
  if (!userInfo) {
    return {};
  }

  return {
    ...userInfo,
    fullName: `${userInfo.firstName} ${userInfo.lastName}`.trim()
  };
}

let currentUser = null;
let currentUserRequest = null;

export class SecurityService {

  async getUserInfo({ force = false, throwError = false } = {}) {
    if (currentUser && !force) {
      return currentUser;
    }

    if (currentUserRequest) {
      return currentUserRequest;
    }

    return currentUserRequest = authGatewayApi.get('/userinfo', {
      skipAuthentication: throwError
    }).then(userInfo => {
      currentUser = getUser(userInfo);
      return this.getAccessToken();
    }).then(accessToken => {
      currentUser.roles = accessToken.aud_roles || [];
      return currentUser;
    }).finally(() => {
      currentUserRequest = null;
    });
  }

  async getAccessToken() {
    return await authGatewayApi.get('/token', {
      skipAuthentication: true,
      params: {
        clientId: config.auth.clientId
      }
    });
  }

  async refreshUserData(userData) {
    const userInfo = await this.getUserInfo();

    return currentUser = getUser({
      ...userInfo,
      ...userData
    });
  }

  async switchTenant(tenant) {
    authGatewayApi.cancelOngoingRequests();
    tenantApi.cancelOngoingRequests();

    await authGatewayApi.put('/session/tenant', null, {
      params: {
        tenant_id: tenant.id
      }
    });

    currentUser = null;
    currentUserRequest = null;

    changeSitePath(tenant.sitePath);
  }

  retrieveState(stateString) {
    try {
      return stateString ? JSON.parse(atob(stateString)) : {};
    } catch (error) {
      console.error(error);
      return {};
    }
  }

  getSessionTimeLeft() {
    let expirationTime = getCookieValue('vidp.session.exp');

    if (!expirationTime) {
      return 0;
    }

    expirationTime = parseInt(expirationTime);

    if (!expirationTime || isNaN(expirationTime)) {
      return 0;
    }

    return expirationTime - (new Date().getTime() / 1000);
  }

  async refreshSession() {
    return authGatewayApi.put('/session/refresh');
  }

  logout() {
    const { auth } = config;

    const params = {
      post_logout_redirect_uri: `${getBaseUrl()}/logout`
    };

    setTimeout(() => {
      window.location = `${auth.serverUrl}/logout?${qs.stringify(params)}`;
    }, 1000);
  }

}

export default new SecurityService();
