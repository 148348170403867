import React from 'react';
import Grid from 'src/components/Grid';
import Button from 'src/components/Button';

export default function ComingSoonPanel() {
  return (
    <Grid container className="actions">
      <Grid xs={6} />

      <Grid xs={6}>
        <Button
          text="Coming Soon"
          className="btn-gray"
          type="button"
          disabled
          block
        />
      </Grid>
    </Grid>
  );
}
