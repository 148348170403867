import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {Link, withRouter} from 'react-router-dom';
import {NavItem} from 'uui_kit/dist/components/layout/SideNav';
import DropMenu from 'uui_kit/dist/components/fragments/DropMenu/DropMenu';
import Btn from 'uui_kit/dist/components/atoms/Btn/Btn';
import Icon from 'uui_kit/dist/components/atoms/iconography/Icon/Icon';

const IconType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.arrayOf(PropTypes.string)
]);

const MenuItemType = {
  title: PropTypes.string.isRequired,
  icon: IconType,
  href: PropTypes.string,
  to: PropTypes.string,
  onClick: PropTypes.func
};

class MenuItem extends React.PureComponent {

  static propTypes = {
    ...MenuItemType,
    items: PropTypes.arrayOf(
      PropTypes.shape(MenuItemType)
    )
  };

  renderLink = props => {
    const {onClick} = this.props;

    return (
      <Link
        to={this.props.to}
        replace={props.replace}
        ref={props.ref}
        title={props.title}
        target={props.target}
        className={classNames(props.className, props.activeClassName)}
        style={{
          ...props.style,
          ...props.activeStyle
        }}
        onClick={onClick}
      >
        {props.children}
      </Link>
    );
  }

  renderDropMenu = title => props => {
    return (
      <DropMenu
        id={`submenu${title.replace(/ /g, '')}`}
        ref={props.ref}
      >
        <DropMenu.Toggle bsRole="toggle">
          <Btn
            variant="subtle"
            className={classNames(props.className, props.activeClassName)}
            style={{
              ...props.style,
              ...props.activeStyle
            }}
          >
            {props.children}
          </Btn>
        </DropMenu.Toggle>

        <DropMenu.Menu bsRole="menu">
          {this.props.items.map(item => {
            const target = item.href ? '_blank' : null;

            return (
              <DropMenu.MenuItem
                key={item.title}
                href={item.href}
                to={item.to}
                target={target}
                before={(
                  <Icon icon={item.icon} color="gray" fw />
                )}
              >
                {item.title}
              </DropMenu.MenuItem>
            );
          })}
        </DropMenu.Menu>
      </DropMenu>
    );
  }

  isActive() {
    const {pathname} = this.props.location;
    return this.props.to === pathname;
  }

  render() {
    const {title, href, to, items, icon, onClick} = this.props;
    const active = this.isActive();

    let renderComponent = null;
    let target = null;
  
    if (to) {
      renderComponent = this.renderLink;
    } else if (items) {
      renderComponent = this.renderDropMenu(title);
    } else if (href) {
      target = '_blank';
    }
  
    return (
      <NavItem
        text={title}
        href={href}
        target={target}
        active={active}
        before={icon && (
          <Icon
            icon={icon}
            color={active ? 'primary' : 'gray'}
            fw
          />
        )}
        onClick={onClick}
        renderComponent={renderComponent}
      />
    );
  }

};

export default withRouter(MenuItem);
