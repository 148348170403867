import HomeActionType from 'src/modules/home/HomeActionType';

const initialState = {
  tenant: {
    loading: false,
    products: []
  },
  recommended: {
    loading: false,
    products: []
  },
  available: {
    loading: false,
    products: []
  },
  error: false
};

export default function(state = initialState, action) {
  switch(action.type) {
    case HomeActionType.LOAD_TENANT_PRODUCTS_REQUEST:
      return {
        ...state,
        tenant: {
          ...initialState.tenant,
          loading: true
        },
        recommended: {
          ...initialState.recommended,
          loading: true
        },
        available: {
          ...initialState.available,
          loading: true
        }
      };

    case HomeActionType.LOAD_NOT_LICENSED_PRODUCTS_REQUEST:
      return {
        ...state,
        recommended: {
          ...initialState.recommended,
          loading: true
        },
        available: {
          ...initialState.available,
          loading: true
        }
      };

    case HomeActionType.LOAD_TENANT_PRODUCTS_SUCCESS:
      return {
        ...state,
        tenant: {
          loading: false,
          products: action.payload
        }
      };

    case HomeActionType.LOAD_NOT_LICENSED_PRODUCTS_SUCCESS: {
      const {recommendedProducts, availableProducts} = action.payload;
      return {
        ...state,
        recommended: {
          loading: false,
          products: recommendedProducts
        },
        available: {
          loading: false,
          products: availableProducts
        }
      };
    }

    case HomeActionType.LOAD_TENANT_PRODUCTS_FAIL:
    case HomeActionType.LOAD_NOT_LICENSED_PRODUCTS_FAIL:
      return {
        ...state,
        tenant: {
          ...state.tenant,
          loading: false
        },
        recommended: {
          ...state.recommended,
          loading: false
        },
        available: {
          ...state.available,
          loading: false
        },
        error: true
      };

    case HomeActionType.RESET:
      return {
        ...initialState
      };

    default: return state;
  }
};
