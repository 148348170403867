export const CHANGE_PASSWORD_REQUEST = 'USER_PASSWORD/CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS = 'USER_PASSWORD/CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAIL = 'USER_PASSWORD/CHANGE_PASSWORD_FAIL';

export const RESET = 'USER_PASSWORD/RESET';

export default {
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAIL,
  RESET
};
