import isEmpty from 'lodash/isEmpty';
import tenantApi from 'src/services/TenantApi';

export class TagService {

  async getTags({resourceType, tags}) {
    const params = {
      resourceType
    };

    if (!isEmpty(tags)) {
      params.name = tags.map(tag => tag.name);
      params.value = tags.map(tag => tag.value);
    }

    return tenantApi.get('/tags', {
      params
    });
  }

}

export default new TagService();
