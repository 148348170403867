import React from 'react';
import PropTypes from 'prop-types';
import {Helmet} from 'react-helmet';
import {WrapperWithMenuProvider} from 'uui_kit';
import NavigationBar from 'src/theme/NavigationBar';

const ORIGINAL_TITLE = document.title;

export default class StandardTheme extends React.PureComponent {

  static propTypes = {
    children: PropTypes.node.isRequired
  }

  render() {
    return (
      <>
        <Helmet
          titleTemplate="%s | Vector Solutions App Library"
          defaultTitle={ORIGINAL_TITLE}
        />
        <WrapperWithMenuProvider hasTopBar hasSideNav>
          <NavigationBar />

          {this.props.children}
        </WrapperWithMenuProvider>
      </>
    )
  }
}
