import {useMemo, useState, useCallback} from 'react';
import debounce from 'lodash/debounce';

export function useDebounceCallback(callback, deps, maxWait = 600) {
  return useMemo(() => {
    return debounce(callback, maxWait);
  }, [...deps, callback, maxWait]); //eslint-disable-line react-hooks/exhaustive-deps
}

export function useDebounceState(initialValue = false, {debounceWait} = {}) {
  const [value, setImmediateValue] = useState(initialValue);
  const setDebouncedError = useDebounceCallback(setImmediateValue, [], debounceWait);

  const setState = useCallback((newValue, immediate) => {
    setDebouncedError(newValue);

    if (immediate) {
      setDebouncedError.flush();
    }
  }, [setDebouncedError]);

  return [value, setState];
}

export function useError(initialError, {debounceWait} = {}) {
  const [error, setDebouncedError] = useDebounceState(initialError, debounceWait);

  const setError = useCallback((newError, immediate) => {
    setDebouncedError(newError, !newError || immediate);
  }, [setDebouncedError]);

  return [error, setError];
}
