import SecurityRole from "src/security/SecurityRole";

const menus = [
  {
    items: [
      {
        title: 'Home',
        icon: ['far', 'home'],
        to: '/'
      },
      {
        title: 'My Apps',
        icon: ['far', 'rocket'],
        to: '/products/my'
      },
      {
        title: 'Recommended',
        icon: ['fal', 'lightbulb-exclamation'],
        to: '/products/recommended',
        requiredRoles: [SecurityRole.TENANT_ADMIN]
      },
      {
        title: 'Discover',
        icon: ['far', 'grip-horizontal'],
        to: '/products/discover',
        requiredRoles: [SecurityRole.TENANT_ADMIN]
      }
    ]
  },
  {
    title: 'Resources',
    items: [
      {
        title: 'Help',
        icon: ['far', 'question-circle'],
        items: [
          {
            title: "TargetSolutions",
            icon: ['far', 'external-link'],
            href: "http://help.targetsolutions.com/"
          },
          {
            title: "Check It",
            icon: ['far', 'external-link'],
            href: "https://help.targetsolutions.com/hc/en-us/sections/360000108026-TargetSolutions-Check-It-"
          },
          {
            title: "Scheduling",
            icon: ['far', 'external-link'],
            href: "https://help.crewsense.com/portal/kb/crewsense"
          }
        ]
      }
    ]
  }
];

export default menus;