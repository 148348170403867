import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import {TransitionGroup} from 'react-transition-group';
import {Heading} from 'uui_kit';
import SimpleTransition from 'src/components/SimpleTransition';
import Grid from 'src/components/Grid';
import LoadingSpinner from 'src/components/LoadingSpinner';
import ProductTile from 'src/components/ProductTile';
import PermissionsProvider from 'src/security/PermissionsProvider';

export default class ProductListSection extends React.PureComponent {

  static propTypes = {
    title: PropTypes.string.isRequired,
    loading: PropTypes.bool,
    hideIfEmpty: PropTypes.bool,
    products: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.oneOfType([
        PropTypes.number, PropTypes.string
      ]),
      name: PropTypes.string,
      description: PropTypes.string,
      logoUrl: PropTypes.string,
      iconUrl: PropTypes.string,
      licensed: PropTypes.bool,
      comingSoon: PropTypes.bool,
      entryPointUrl: PropTypes.string,
      signInUrl: PropTypes.string,
      freeTrialUrl: PropTypes.string,
      contactUrl: PropTypes.string,
      detailUrl: PropTypes.string,
    }))
  }

  static defaultProps = {
    loading: false,
    hideIfEmpty: false
  }

  render() {
    const {title, loading, hideIfEmpty, products} = this.props;

    const hideSection = hideIfEmpty && isEmpty(products);

    return (
      <SimpleTransition in={!hideSection} name="fadedown">
        <div className="product-section">
          <Heading
            level="2"
            appearance="5"
            color="product"
            bold
            uppercase
          >
            {title}
          </Heading>

          {loading && (
            <div style={{margin: '50px 30px 0px'}}>
              <LoadingSpinner size={100} />
            </div>
          )}

          <Grid container>
            <TransitionGroup component={null}>
              {!loading && products.map((product, index) => (
                <PermissionsProvider key={product.id} roles={product.roles}>
                  <SimpleTransition order={index} name="fadedown">
                    <Grid xs={12} sm={6} lg={4} xl={3}>
                      <ProductTile {...product} />
                    </Grid>
                  </SimpleTransition>
                </PermissionsProvider>
              ))}
            </TransitionGroup>
          </Grid>
        </div>
      </SimpleTransition>
    );
  }
}
