export const LOAD_PRODUCTS_REQUEST = 'RECOMMENDED/LOAD_PRODUCTS_REQUEST';
export const LOAD_PRODUCTS_SUCCESS = 'RECOMMENDED/LOAD_PRODUCTS_SUCCESS';
export const LOAD_PRODUCTS_FAIL = 'RECOMMENDED/LOAD_PRODUCTS_FAIL';

export const RESET = 'RECOMMENDED/RESET';

export default {
  LOAD_PRODUCTS_REQUEST,
  LOAD_PRODUCTS_SUCCESS,
  LOAD_PRODUCTS_FAIL,
  RESET
};
