import SecurityActionType from 'src/security/SecurityActionType';

const initialState = {
  nearEnd: false,
  refreshing: false,
  error: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SecurityActionType.SESSION_NEAR_END:
      return {
        ...state,
        nearEnd: true,
        error: false
      };

    case SecurityActionType.SESSION_OK:
      return {
        ...state,
        nearEnd: false,
        error: false
      };

    case SecurityActionType.SESSION_REFRESH_REQUEST:
      return {
        ...state,
        refreshing: true,
        error: false
      };

    case SecurityActionType.SESSION_REFRESH_SUCCESS:
      return {
        ...state,
        nearEnd: false,
        refreshing: false,
        error: false
      };

    case SecurityActionType.SESSION_REFRESH_ERROR:
      return {
        ...state,
        refreshing: false,
        error: true
      };

    default:
      return state;
  }
}
