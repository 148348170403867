import React from 'react';
import PropTypes from 'prop-types';
import {Dropdown} from 'react-bootstrap';
import {Heading, DetailHeading} from 'uui_kit';
import UserMenuButton from 'src/menu/UserMenuButton';
import DropdownContainer from 'src/components/DropdownContainer';
import Button from 'src/components/Button';
import Avatar from 'src/components/Avatar';
import UserNavigationMenu from 'src/menu/UserNavigationMenu';

export default class UserDropDownMenu extends React.PureComponent {

  static propTypes = {
    open: PropTypes.bool,
    user: PropTypes.shape({
      fullName: PropTypes.string,
      email: PropTypes.string
    }),
    className: PropTypes.string,
    onSignOut: PropTypes.func,
    onOpen: PropTypes.func,
    onClose: PropTypes.func
  };

  static defaultProps = {
    open: false,
    user: {},
    onSignOut: () => {},
    onOpen: () => {},
    onClose: () => {}
  }

  handleToggle = isOpen => {
    if (typeof(isOpen) !== 'boolean') {
      isOpen = !this.props.open;
    }

    if (isOpen) {
      this.handleOpen();
    } else {
      this.handleClose();
    }
  }

  handleOpen = () => {
    this.props.onOpen();
  }

  handleClose = () => {
    this.props.onClose();
  }

  render() {
    const {open, user, onSignOut} = this.props;

    return (
      <Dropdown
        id="userMenu"
        open={open}
        className="user-dropdown-menu"
        onToggle={this.handleToggle}
      >
        
        <UserMenuButton
          bsRole="toggle"
          name={user.fullName}
          onClick={this.handleToggle}
        />
        
        <DropdownContainer bsRole="menu">
          <div className="dropdown-menu dropdown-menu-right">
            <div className="user-menu-body">
              <div className="user-avatar">
                <Avatar name={user.fullName} size="xlarge" />
              </div>

              <div className="user-panel">
                <div className="user-info">
                  <Heading level="5" bold>
                    {user.fullName}
                  </Heading>

                  <DetailHeading color="gray" uppercase={false}>
                    {user.email}
                  </DetailHeading>
                </div>

                <UserNavigationMenu onItemClick={this.handleClose} />
              </div>
            </div>

            <div className="user-menu-footer">
              <Button
                variant="subtle"
                text="Sign out"
                icon={['fas', 'sign-out']}
                onClick={onSignOut}
              />
            </div>
          </div>
        </DropdownContainer>
      </Dropdown>
    );
  }

}

