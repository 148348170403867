import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';

class PermissionsProvider extends React.Component {

  static propTypes = {
    roles: PropTypes.array
  }

  isAllowed = () => {
    const {roles: requiredRoles, userRoles} = this.props

    if (isEmpty(requiredRoles))
      return true

    if (isEmpty(userRoles))
      return false;

    return requiredRoles.every(requiredRole => userRoles.includes(requiredRole))
  }

  render() {
    return this.isAllowed() ? this.props.children : null
  }
}

const withRedux = connect(state => ({
  userRoles: get(state, 'security.user.roles')
}));

export default withRedux(PermissionsProvider);
