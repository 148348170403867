export function getCookies() {
  const cookieString = document.cookie;

    if (!cookieString || cookieString.trim().length <= 0) {
      return [];
    }

    return cookieString.split(';').map(cookie => {
      cookie = cookie.split('=');

      return {
        name: cookie[0].trim(),
        value: (cookie[1] ? cookie[1].trim() : '')
      };
    });
}

export function getCookieValue(name) {
  const cookie = getCookies().find(cookie => cookie.name === name);

  return cookie ? cookie.value : null;
}