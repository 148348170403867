import React from 'react';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';
import withRouter from 'src/route/withRouter.js';
import LoadingPage from 'src/components/LoadingPage';
import ErrorPage from 'src/components/ErrorPage';
import {
  processAuthenticationResponse,
  processAuthenticationError
} from 'src/security/SecurityActions';

class SecurityAuthentication extends React.PureComponent {

  componentDidMount() {
    const {params = {}} = this.props.route;
    const {id_token, state, error, error_description} = params;

    if (id_token) {
      this.props.processAuthenticationResponse(state);
    } else {
      this.props.processAuthenticationError(error, error_description);
    }
  }
  
  render() {
    const {security} = this.props;

    if (security.error) {
      return (
        <ErrorPage />
      );
    }

    if (security.responseProcessed) {
      const previousState = security.previousState || {};

      return (
        <Redirect
          to={{
            pathname: previousState.path || '/',
            search: previousState.search
          }}
        />
      );
    }

    return (
      <LoadingPage />
    );
  }

}

const withRedux = connect(state => ({
  security: state.security
}), {
  processAuthenticationResponse,
  processAuthenticationError
});

export default withRedux(withRouter(SecurityAuthentication));