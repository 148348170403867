import currentUserService from 'src/services/CurrentUserService';
import licenseService from 'src/services/LicenseService';
import productService from 'src/services/ProductService';
import HomeActionType from 'src/modules/home/HomeActionType';
import SecurityRole from 'src/security/SecurityRole';

const loadLicensedProducts = () => async dispatch => {
  try {
    dispatch({
      type: HomeActionType.LOAD_TENANT_PRODUCTS_REQUEST
    });

    const licensedProducts = await licenseService.getProducts();
    const userEntitledProducts = licensedProducts.filter(product => product.userEntitled);

    dispatch({
      type: HomeActionType.LOAD_TENANT_PRODUCTS_SUCCESS,
      payload: userEntitledProducts
    });

    return licensedProducts;
  } catch (error) {
    console.error(error);

    dispatch({
      type: HomeActionType.LOAD_TENANT_PRODUCTS_FAIL
    });

    throw error;
  }
};

const loadNotLicensedProducts = (licensedProducts = []) => async dispatch => {
  const currentUser = await currentUserService.get();
  const isTenantAdmin = currentUser.roles?.includes(SecurityRole.TENANT_ADMIN);

  if (!isTenantAdmin) {
    return false;
  }

  try {
    dispatch({
      type: HomeActionType.LOAD_NOT_LICENSED_PRODUCTS_REQUEST
    });

    const allProducts = await productService.getAll(licensedProducts);
    const availableProducts = await productService.getAvailable(allProducts);
    const recommendedProducts = await productService.getRecommended(allProducts);

    dispatch({
      type: HomeActionType.LOAD_NOT_LICENSED_PRODUCTS_SUCCESS,
      payload: {
        availableProducts,
        recommendedProducts
      }
    });
  } catch (error) {
    console.error(error);

    dispatch({
      type: HomeActionType.LOAD_NOT_LICENSED_PRODUCTS_FAIL
    });
  }
};

export const loadProducts = () => async dispatch => { 
  let licensedProducts = [];

  try {
    licensedProducts = await loadLicensedProducts()(dispatch);
  } catch (error) {
    return;
  }

  await loadNotLicensedProducts(licensedProducts)(dispatch);
};

export function reset() {
  return {
    type: HomeActionType.RESET
  };
}
