import get from 'lodash/get';
import find from 'lodash/find';
import sortBy from 'lodash/sortBy';
import filter from 'lodash/filter';
import securityService from 'src/services/SecurityService';
import tenantApi from 'src/services/TenantApi';
import SecurityRole from 'src/security/SecurityRole';
import {getTenantAdminAppUrl} from 'src/util/vidp';

export class CurrentTenantService {

  async get() {
    const user = await securityService.getUserInfo();

    if (!user) {
      return null;
    }

    return find(user.tenants, {
      selected: true
    });
  }

  async getAvailable() {
    const user = await securityService.getUserInfo();

    if (!user) {
      return [];
    }

    const enabledTenants = filter(user.tenants, {
      enabled: true
    });

    return sortBy(enabledTenants, tenant => tenant.name.toUpperCase());
  }

  async getProducts() {
    const tenant = await this.get();

    if (!tenant || !tenant.id) {
      return [];
    }

    let license = await tenantApi.get(`/tenants/${tenant.id}/license`).catch(error => {
      const {response} = error;

      if (response && response.status === 404) {
        return {};
      }

      return Promise.reject(error);
    });

    const products = get(license, 'licenseProducts', []).map(licenseProduct => licenseProduct.product);

    products.push({
      id: 'tenant-admin',
      name: 'Administration',
      description: 'Access administration tools to manage your Vector Solutions applications and users.',
      entryPointUrl: getTenantAdminAppUrl(),
      roles: [SecurityRole.TENANT_ADMIN]
    });

    return products.map((product) => {
      product.licensed = true;
      return product;
    });
  }

  async getTags() {
    const tenant = await this.get();

    if (!tenant || !tenant.id) {
      return [];
    }

    return tenantApi.get(`/tenants/${tenant.id}/tags`);
  }

}

export default new CurrentTenantService();
