import {combineReducers} from 'redux';
import home from 'src/modules/home/HomeReducer';
import licensed from 'src/modules/licensed/LicensedReducer';
import discover from 'src/modules/discover/DiscoverReducer';
import recommended from 'src/modules/recommended/RecommendedReducer';
import user from 'src/modules/user/UserReducer';

export default combineReducers({
  home,
  licensed,
  discover,
  recommended,
  user
});