export const LOAD_PRODUCTS_REQUEST = 'DISCOVER/LOAD_PRODUCTS_REQUEST';
export const LOAD_PRODUCTS_SUCCESS = 'DISCOVER/LOAD_PRODUCTS_SUCCESS';
export const LOAD_PRODUCTS_FAIL = 'DISCOVER/LOAD_PRODUCTS_FAIL';

export const RESET = 'DISCOVER/RESET';

export default {
  LOAD_PRODUCTS_REQUEST,
  LOAD_PRODUCTS_SUCCESS,
  LOAD_PRODUCTS_FAIL,
  RESET
};
