import isEmpty from 'lodash/isEmpty';
import tenantApi from 'src/services/TenantApi';
import licenseService from 'src/services/LicenseService';
import currentTenantService from 'src/services/CurrentTenantService';
import tagService from 'src/services/TagService';

function getSortScore(product) {
  if (product.licensed) {
    return 50;
  }

  if (product.recommended && product.freeTrialUrl) {
    return 40;
  }

  if (product.recommended) {
    const score = (product.score || 0) + 1;
    return 30 + (score / 100000);
  }

  if (product.freeTrialUrl) {
    return 20;
  }

  if (product.contactUrl) {
    return 10;
  }

  return 0;
}

async function flagProducts(allProducts, licensedProducts = null, recommendedProducts = null) {
  if (!licensedProducts) {
    licensedProducts = await licenseService.getProducts();
  }

  if (!recommendedProducts) {
    recommendedProducts = await productService.fetchRecommendedProducts();
  }

  if (isEmpty(licensedProducts)) {
    return allProducts;
  }

  allProducts = [
    ...allProducts,
    ...recommendedProducts,
    ...licensedProducts
  ]

  const productsById = new Map(allProducts.map(product => [product.id, product]));
  return Array.from(productsById.values());
}

export class ProductService {

  async getAll(licensedProducts = null, recommendedProducts = null) {
    let allProducts = await tenantApi.get('/products');

    if (!allProducts) {
      return [];
    }

    allProducts = await flagProducts(allProducts, licensedProducts, recommendedProducts);

    return allProducts.sort((productA, productB) => {
      return getSortScore(productB) - getSortScore(productA);
    });
  }

  async getAvailable(allProducts = null, tenantProducts = null) {
    if (!allProducts) {
      allProducts = await this.getAll(tenantProducts);
    }


    return allProducts.filter(product => !product.licensed && !product.recommended);
  }

  async getRecommended(allProducts = null, tenantProducts = null) {
    if (!allProducts) {
      allProducts = await this.getAll(tenantProducts);
    }
    
    return allProducts.filter(product => !product.licensed && product.recommended);
  }

  async fetchRecommendedProducts() {
    const tenantTags = await currentTenantService.getTags();

    if (isEmpty(tenantTags)) {
      return [];
    }

    const recommendedTags = await tagService.getTags({
      resourceType: 'product',
      tags: tenantTags
    });

    if (isEmpty(recommendedTags)) {
      return [];
    }

    const tagsByProductId = recommendedTags.reduce((result, tag) => {
      const productTags = result.get(tag.resourceId) || [];
      productTags.push(tag);

      result.set(tag.resourceId, productTags);
      return result;
    }, new Map());

    const products = await tenantApi.get('/products', {
      params: {
        ids: Array.from(tagsByProductId.keys())
      }
    });

    return products.map(product => {
      product.recommended = true
      product.score = (tagsByProductId.get(product.id) || []).length;
      
      return product;
    });
  }

}

const productService = new ProductService();

export default productService;