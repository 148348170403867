import React from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import get from 'lodash/get';
import MenuSection from 'src/menu/MenuSection';
import MenuItem from 'src/menu/MenuItem';

export default function UserNavigationMenu(props) {
  const {onItemClick} = props;

  const federated = useSelector(state => get(state, 'security.tenant.federated'));

  return (
    <MenuSection onItemClick={onItemClick}>
        <MenuItem
          to="/user/profile"
          title="My Profile"
          icon={['fas', 'user']}
        />

        {!federated && (
          <MenuItem
            to="/user/password"
            title="Change Password"
            icon={['fas', 'key']}
          />
        )}
      </MenuSection>
  );
}

UserNavigationMenu.propTypes = {
  onItemClick: PropTypes.func
};

UserNavigationMenu.defaultProps = {
  onItemClick: () => {}
};
