import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import cleanProps from 'clean-react-props';
import {Link} from 'react-router-dom';
import Btn from 'uui_kit/dist/components/atoms/Btn/Btn';

export default class Button extends React.PureComponent {
  static propTypes = {
    ...Btn.propTypes,
    to: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string)
    ]),
    children: PropTypes.node
  }

  renderLink = props => {
    return (
      <Link
        to={this.props.to}
        replace={props.replace}
        ref={props.ref}
        title={props.title}
        target={props.target}
        className={classNames(props.className, props.activeClassName)}
        style={{
          ...props.style,
          ...props.activeStyle
        }}
      >
        {props.children}
      </Link>
    );
  }

  renderButton = type => ({children, ...props}) => {
    const {onClick} = this.props;

    return (
      <button
        type={type}
        onClick={onClick}
        {...cleanProps(props)}
      >
        {children}
      </button>
    );
  }

  render() {
    const {
      to,
      type,
      ...props
    } = this.props;


    let renderComponent = null;

    if (to) {
      renderComponent = this.renderLink;
    } else if (['submit', 'button'].includes(type)) {
      renderComponent = this.renderButton(type);
    }

    return (
      <Btn
        {...props}
        renderComponent={renderComponent}
      />
    );
  }
}
