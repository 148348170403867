import LicensedActionType from 'src/modules/licensed/LicensedActionType';

const initialState = {
  loading: false,
  products: [],
  error: false
};

export default function(state = initialState, action) {
  switch(action.type) {
    case LicensedActionType.LOAD_PRODUCTS_REQUEST:
      return {
        ...state,
        ...initialState,
        loading: true
      };

    case LicensedActionType.LOAD_PRODUCTS_SUCCESS:
      return {
        ...state,
        loading: false,
        products: action.payload
      };

    case LicensedActionType.LOAD_PRODUCTS_FAIL:
      return {
        ...state,
        loading: false,
        error: true
      };

    case LicensedActionType.RESET:
      return {
        ...initialState
      };

    default: return state;
  }
};
