import UserPasswordActionType from 'src/modules/user/password/UserPasswordActionType';

const initialState = {
  loading: false,
  success: false,
  error: false,
  errorMessage: null
};

export default function(state = initialState, action) {
  switch(action.type) {
    case UserPasswordActionType.CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
        error: false,
        errorMessage: null
      };

    case UserPasswordActionType.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true
      };

    case UserPasswordActionType.CHANGE_PASSWORD_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload
      };

    case UserPasswordActionType.RESET:
      return {
        ...initialState
      };

    default: return state;
  }
};
