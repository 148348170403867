import React from 'react';
import {connect} from 'react-redux';
import debounce from 'src/util/debounce';
import {triggerDeviceChange} from 'src/device/DeviceActions';

class DeviceObserver extends React.PureComponent {
  
  componentDidMount() {
    window.addEventListener('resize', this.handleChange);
    this.props.triggerDeviceChange();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleChange);
  }

  handleChange = debounce(() => {
    this.props.triggerDeviceChange();
  }, 250)

  render() {
    return null;
  }
}

const withRedux = connect(null, {
  triggerDeviceChange
});

export default withRedux(DeviceObserver);
