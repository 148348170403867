import React from 'react';
import PropTypes from 'prop-types';
import cleanProps from 'clean-react-props';
import RootCloseWrapper from 'react-overlays/lib/RootCloseWrapper';

export default class DropdownContainer extends React.PureComponent {

  static propTypes = {
    open: PropTypes.bool,
    rootCloseEvent: PropTypes.any,
    children: PropTypes.any,
    onClose: PropTypes.func
  }

  static defaultProps = {
    open: false,
    onClose: () => {}
  }

  handleRootClose = event => {
    this.props.onClose(event, {
      source: 'rootClose'
    });
  }

  render() {
    const {open, rootCloseEvent, children, ...props} = this.props;

    return (
      <RootCloseWrapper
        disabled={!open}
        onRootClose={this.handleRootClose}
        event={rootCloseEvent}
      >
        <div {...cleanProps(props)}>
          {children}
        </div>
      </RootCloseWrapper>
    );
  }

}

