import React, {useState, useMemo, useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Card, Alert, Heading} from 'uui_kit';
import get from 'lodash/get';
import Page from 'src/components/Page';
import Grid from 'src/components/Grid';
import Button from 'src/components/Button';
import PasswordInput from 'src/components/PasswordInput';
import LoadingSpinner from 'src/components/LoadingSpinner';
import {changePassword, reset} from 'src/modules/user/password/UserPasswordActions';

export default function UserPassword() {
  const dispatch = useDispatch();
  const mobile = useSelector(state => get(state, 'device.type.mobile', false));

  const {
    loading,
    success,
    error,
    errorMessage
  } = useSelector(state => get(state, 'modules.user.password', {}));

  const [previousPassword, setPreviousPassword] = useState({value: '', valid: false});
  const [proposedPassword, setProposedPassword] = useState({value: '', valid: false});
  const [passwordConfirmation, setPasswordConfirmation] = useState({value: '', valid: false});

  const changed = useMemo(() => {
    return Boolean(previousPassword.value || proposedPassword.value || passwordConfirmation.value);
  }, [previousPassword, proposedPassword, passwordConfirmation])

  const changePasswordAllowed = useMemo(() => {
    if (!changed) {
      return false;
    }

    return previousPassword.valid && proposedPassword.valid && passwordConfirmation.valid;
  }, [changed, previousPassword, proposedPassword, passwordConfirmation]);

  const handlePreviousPasswordChanged = useCallback(({touched, valid, password}) => {
    setPreviousPassword({
      value: password,
      touched,
      valid
    });
  }, []);

  const handleProposedPasswordChanged = useCallback(({touched, valid, password}) => {
    setProposedPassword({
      value: password,
      touched,
      valid
    });
  }, []);

  const handlePasswordConfirmationChanged = useCallback(({touched, valid, password}) => {
    setPasswordConfirmation({
      value: password,
      touched,
      valid
    });
  }, []);

  const handleClear = useCallback(() => {
    setPreviousPassword({value: '', valid: false});
    setProposedPassword({value: '', valid: false});
    setPasswordConfirmation({value: '', valid: false});
  }, []);

  const handleSave = useCallback(async event => {
    event.preventDefault();

    if (!changePasswordAllowed) {
      return;
    }

    await dispatch(changePassword({
      previousPassword: previousPassword.value,
      proposedPassword: proposedPassword.value,
      proposedPasswordConfirmation: passwordConfirmation.value
    }));

    handleClear();
  }, [
    changePasswordAllowed,
    previousPassword,
    proposedPassword,
    passwordConfirmation,
    dispatch,
    handleClear
  ]);

  useEffect(() => () => {
    dispatch(reset());
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Page title="My Profile" className="user-profile">
      <Card>
        <form name="changePasswordForm" className="user-profile-container">
          <Grid container direction="column" justify="start" align="center">
            {success && (
              <Grid xs={12}>
                <Alert variant="confirm">
                  Your password was updated successfully.
                </Alert>
              </Grid>
            )}

            {error && (
              <Grid xs={12}>
                <Alert variant={errorMessage ? 'warning' : 'danger'}>
                  {errorMessage || 'An error happened while updating your password.'}
                </Alert>
              </Grid>
            )}

            <Grid
              container
              xs={12}
              sm={8}
              md={6}
              lg={4}
              spacing={2}
              align="end"
            >
              <Grid xs={12}>
                <Heading
                  level="2"
                  appearance="2"
                  color="product"
                  bold
                  className="text-center"
                >
                  Change Password
                </Heading>
              </Grid>
            </Grid>

            <Grid
              container
              xs={12}
              sm={8}
              md={6}
              lg={4}
              spacing={2}
              align="end"
            >
              <Grid xs={11}>
                <PasswordInput
                  validations={{
                    required: true,
                    minimum: 6
                  }}
                  name="previousPassword"
                  autoComplete="current-password"
                  label="Current Password"
                  touched={previousPassword.touched}
                  value={previousPassword.value}
                  disabled={loading}
                  onChange={handlePreviousPasswordChanged}
                />
              </Grid>

              <Grid xs={11}>
                <PasswordInput
                  validations={{
                    required: true,
                    minimum: 6
                  }}
                  name="proposedPassword"
                  autoComplete="new-password"
                  label="New Password"
                  touched={proposedPassword.touched}
                  value={proposedPassword.value}
                  disabled={loading}
                  onChange={handleProposedPasswordChanged}
                />
              </Grid>

              <Grid xs={11}>
                <PasswordInput
                  validations={{
                    required: true,
                    equalTo: proposedPassword.value
                  }}
                  name="proposedPasswordConfirmation"
                  autoComplete="off"
                  label="Confirm New Password"
                  touched={passwordConfirmation.touched}
                  value={passwordConfirmation.value}
                  disabled={loading}
                  onChange={handlePasswordConfirmationChanged}
                />
              </Grid>
            </Grid>

            <Grid 
              container
              xs={12}
              sm={11}
              spacing={2}
              justify={mobile ? 'start' : 'end'}
            >
              <Grid xs={6} sm={4} md={2}>
                <Button
                  type="button"
                  block
                  variant="default"
                  text="Cancel"
                  disabled={!changed || loading}
                  onClick={handleClear}
                />
              </Grid>

              <Grid xs={5} sm={4} md={2}>
                <Button
                  type="submit"
                  block
                  variant="primary"
                  text="Save"
                  disabled={!changePasswordAllowed || loading}
                  onClick={handleSave}
                >
                  {loading && (
                    <LoadingSpinner size={25} color="white" />
                  )}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Card>
    </Page>
  );
}
