import React, {useState, useMemo, useEffect} from 'react';
import featureFlagService from 'src/services/FeatureFlagService';

export default function featureFlagging(featureFlagMapOrFunction) {
  return function withFeatureFlags(WrappedComponent) {
    return function WithFeatureFlag(props) {
      const [featureFlagProps, setFeatureFlagProps] = useState({});

      const featureFlagMap = useMemo(() => {
        return typeof(featureFlagMapOrFunction) === 'function' ? featureFlagMapOrFunction(props) : featureFlagMapOrFunction;
      }, [props]);

      useEffect(() => {
        async function loadFeatureFlags() {
          const newFeatureFlagProps = {};

          for (let propName in featureFlagMap) {
            const featureFlag = featureFlagMap[propName];
            newFeatureFlagProps[propName] = featureFlagProps[propName] ?? await featureFlagService.get(featureFlag);
          }

          setFeatureFlagProps(newFeatureFlagProps);
        }

        loadFeatureFlags();
      }, [featureFlagMap]); //eslint-disable-line react-hooks/exhaustive-deps

      return (
        <WrappedComponent {...props} {...featureFlagProps} />
      );
    }
  }
}