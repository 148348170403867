import React from 'react';
import {withRouter} from 'react-router-dom';
import qs from 'qs';

export default function(Component) {
  const WrapComponent = props => {
    const {location} = props;

    let params = {}

    if (location.search) {
      params = qs.parse(location.search, {
        ignoreQueryPrefix: true
      });
    }

    const route = {
      path: location.pathname || '',
      params,
      hash: location.hash || '',
      state: location.state || {}
    };

    return (
      <Component
        {...props}
        route={route}
      />
    );
  };

  WrapComponent.displayName = `withAppRouter(${Component.displayName || Component.name}`;

  return withRouter(WrapComponent);
}
