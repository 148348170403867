import React from 'react';
import {connect} from 'react-redux';
import get from 'lodash/get';
import SessionTimeoutModal from 'src/components/SessionTimeoutModal';
import {checkSessionExpiration} from 'src/security/SecurityActions';

const DEFAULT_SESSION_CHECK_INTERVAL = 10;

export class SessionObserver extends React.PureComponent {

  timeoutId = null

  componentDidMount() {
    this.start();
  }

  componentWillUnmount() {
    this.stop();
  }

  start() {
    if (this.timeoutId) {
      return;
    }

    this.handleSessionCheck();
  }

  stop() {
    if (!this.timeoutId) {
      return;
    }

    clearTimeout(this.timeoutId);
    this.timeoutId = null;
  }
  
  scheduleSessionCheck(seconds) {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }

    if (!seconds || seconds <= 0) {
      seconds = DEFAULT_SESSION_CHECK_INTERVAL;
    }

    this.timeoutId = setTimeout(this.handleSessionCheck, seconds * 1000);
  }

  handleSessionCheck = async () => {
    let nextCheckSeconds = null;

    try {
      const {authenticated, refreshing} = this.props;

      if (!authenticated || refreshing) {
        return;
      }

      nextCheckSeconds = await this.props.checkSessionExpiration();
    } finally {
      this.scheduleSessionCheck(nextCheckSeconds);
    }
  }

  render() {
    return (
      <SessionTimeoutModal onSessionRefreshed={this.handleSessionCheck} />
    );
  }
}

const withRedux = connect(state => {
  return {
    authenticated: get(state, 'security.authenticated', false),
    refreshing: get(state, 'security.session.refreshing', false)
  };
}, {
  checkSessionExpiration
});

export default withRedux(SessionObserver);
