export const LOAD_PRODUCTS_REQUEST = 'LICENSED/LOAD_PRODUCTS_REQUEST';
export const LOAD_PRODUCTS_SUCCESS = 'LICENSED/LOAD_PRODUCTS_SUCCESS';
export const LOAD_PRODUCTS_FAIL = 'LICENSED/LOAD_PRODUCTS_FAIL';

export const RESET = 'LICENSED/RESET';

export default {
  LOAD_PRODUCTS_REQUEST,
  LOAD_PRODUCTS_SUCCESS,
  LOAD_PRODUCTS_FAIL,
  RESET
};
