import React, {useCallback, useState} from 'react';
import {Dropdown} from 'react-bootstrap';
import {Icon} from 'uui_kit';
import DropdownContainer from 'src/components/DropdownContainer';
import config from 'src/config';

function ProductSwitcherMenu() {
  const [open, setOpen] = useState(false);

  const handleToggle = useCallback(shouldOpen => {
    if (typeof(shouldOpen) !== 'boolean') {
      shouldOpen = !open;
    }

    setOpen(shouldOpen);
  }, [open, setOpen,]);

  return (
    <Dropdown
      id="productSwitcher"
      open={open}
      className="product-switcher-menu"
      onToggle={handleToggle}
    >
      
      <button
        bsRole="toggle"
        className="product-switcher-button"
        type="button"
        onClick={handleToggle}
      >
        <Icon icon={['fas', 'th']} size="lg" />
      </button>
      
      <DropdownContainer bsRole="menu" className="dropdown-menu dropdown-menu-right">
        <iframe
          src={`${config.launch.appUrl}/widget/product-switcher`}
          title="Vector Solution App Switcher"
        />
      </DropdownContainer>
    </Dropdown>
  );
}

export default ProductSwitcherMenu;
