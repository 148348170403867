import React from 'react';
import {connect} from 'react-redux';
import get from 'lodash/get';
import TenantDropdownMenu from 'src/menu/TenantDropdownMenu';
import {switchTenant} from 'src/security/SecurityActions';

class TenantMenu extends React.Component {

  state = {
    isMenuOpen: false
  }

  handleOpenMenu = () => {
    this.setState({
      isMenuOpen: true
    });
  }

  handleCloseMenu = () => {
    this.setState({
      isMenuOpen: false
    });
  }

  handleTenantChange = tenant => {
    this.props.switchTenant(tenant);
  }

  render() {
    const {tenant, availableTenants} = this.props;

    if (!tenant || !tenant.id) {
      return null;
    }

    const {isMenuOpen} = this.state;

    return (
      <TenantDropdownMenu
        open={isMenuOpen}
        tenant={tenant}
        availableTenants={availableTenants}
        onOpen={this.handleOpenMenu}
        onClose={this.handleCloseMenu}
        onChange={this.handleTenantChange}
      />
    );
  }
}

const withRedux = connect(state => {
  return {
    tenant: get(state, 'security.tenant', {}),
    availableTenants: get(state, 'security.availableTenants', {})
  };
}, {
  switchTenant
});

export default withRedux(TenantMenu);
