import React from 'react';
import LoadingSpinner from 'src/components/LoadingSpinner';

export default class LoadingPage extends React.PureComponent {

  render() {
    return (
      <div className="page-loading">
        <LoadingSpinner size={100} />
      </div>
    );
  }
  
}
