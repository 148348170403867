import React from 'react';
import PropTypes from 'prop-types';
import {Btn, Icon} from 'uui_kit';
import Grid from 'src/components/Grid';

ContactPanel.propTypes = {
  freeTrialUrl: PropTypes.string,
  contactUrl: PropTypes.string,
  detailUrl: PropTypes.string
};

export default function ContactPanel({freeTrialUrl, contactUrl, detailUrl}) {
  return (
    <Grid container className="actions">
      <Grid xs={6}>
        {detailUrl && (
          <Btn
            text="Details"
            href={detailUrl}
            variant="subtle"
            target="_blank"
            newTab
            block
          />
        )}
      </Grid>

      <Grid xs={6}>
        {freeTrialUrl && (
          <Btn
            href={freeTrialUrl}
            variant="tertiary"
            target="_blank"
            newTab
            block
          >
            <span>Free Trial </span>
            <Icon icon={['far', 'badge-dollar']} />
          </Btn>
        )}

        {!freeTrialUrl && contactUrl && (
          <Btn
            text="Learn More"
            href={contactUrl}
            variant="default"
            target="_blank"
            newTab
            block
          />
        )}
      </Grid>
    </Grid>
  );
}
