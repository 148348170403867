import React from 'react';
import PropTypes from 'prop-types';
import { Nav } from 'uui_kit/dist/components/layout/SideNav';
import MenuItem from 'src/menu/MenuItem';
import PermissionsProvider from 'src/security/PermissionsProvider';

export default class MenuSection extends React.PureComponent {

  static propTypes = {
    title: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape(MenuItem.propTypes)
    ),
    onItemClick: PropTypes.func,
    children: PropTypes.node
  };

  handleItemClick = child => (...data) => {
    const { onItemClick } = this.props;

    if (child.props.onClick) {
      child.props.onClick(...data);
    }

    if (onItemClick) {
      onItemClick(...data);
    }
  }

  renderChildren() {
    const { onItemClick, children } = this.props;

    if (!onItemClick) {
      return children;
    }

    return React.Children.map(this.props.children, child => {
      if (!child) {
        return null;
      }
      
      return React.cloneElement(child, {
        onClick: this.handleItemClick(child)
      })
    });
  }

  render() {
    const { title, items, onItemClick } = this.props;

    return (
      <Nav
        className="menu-section"
        title={title}
        menuTitle={title}
      >
        {items && items.map(item => (
          <PermissionsProvider roles={item.requiredRoles} key={item.title}>
            <MenuItem
              title={item.title}
              icon={item.icon}
              href={item.href}
              to={item.to}
              items={item.items}
              onItemClick={onItemClick}
            />
          </PermissionsProvider>
        ))}

        {this.renderChildren()}
      </Nav>
    );
  }
}
