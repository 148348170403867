import qs from 'qs';
import config from 'src/config';
import {forever} from 'src/util/promise';
import {getBaseUrl, getSitePath} from 'src/route/routing';
import {generateState} from 'src/util/authentication';

export class AuthenticationService {
  redirectAuthentication() {
    const state = generateState();

    const params = {
      client_id: config.auth.clientId,
      redirect_uri: `${getBaseUrl()}/security/callback`,
      tenant_site_path: getSitePath(),
      scope: 'openid',
      response_type: 'id_token token',
      state
    };

    setTimeout(() => {
      window.location = `${config.auth.serverUrl}/authorize?${qs.stringify(params)}`;
    }, 1000);

    return forever();
  }
}

export default new AuthenticationService();
