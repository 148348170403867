import React from 'react';
import {Redirect} from 'react-router-dom';

export default class SecurityLogout extends React.PureComponent {

  render() {
    return (
      <Redirect to="/" />
    );
  }

}
