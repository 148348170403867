import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Route, Redirect} from 'react-router-dom';
import FeatureFlags from 'src/feature/FeatureFlags';
import featureFlagging from 'src/feature/featureFlagging';
import LoadingPage from 'src/components/LoadingPage';
import ErrorPage from 'src/components/ErrorPage';
import StandardTheme from 'src/theme/StandardTheme';
import AccessType from "src/security/AccessType";
import {redirectAuthentication} from 'src/security/SecurityActions';

class StandardRoute extends React.PureComponent {

  static propTypes = {
    path: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string)
    ]),
    exact: PropTypes.bool,
    access: PropTypes.oneOf([AccessType.PRIVATE, AccessType.PUBLIC]),
    featureFlag: PropTypes.oneOf(FeatureFlags.all()),
    roles: PropTypes.array,
    templateComponent: PropTypes.elementType,
    component: PropTypes.elementType,
    children: PropTypes.node
  }

  static defaultProps = {
    exact: false,
    access: AccessType.PRIVATE,
    themeComponent: StandardTheme
  }

  isLoading() {
    return this.props.security.loading;
  }

  hasError() {
    return this.props.security.error;
  }

  isFeatureFlagOn() {
    const {featureFlag, featureFlagOn} = this.props;

    if (!featureFlag) {
      return true;
    }

    return Boolean(featureFlagOn);
  }

  authenticationRequired() {
    const { access, security } = this.props;

    return access === AccessType.PRIVATE && !security.authenticated
  }

  isAuthorized() {
    if (!this.isFeatureFlagOn()) {
      return false;
    }

    const { roles: requiredRoles, security: { user } } = this.props
    const { roles: userRoles } = user || {}

    if (!requiredRoles)
      return true

    return userRoles && requiredRoles.every(requiredRole => userRoles.includes(requiredRole))
  }

  renderRoute = () => {
    if (this.isLoading()) {
      return (
        <LoadingPage />
      );
    }

    if (this.hasError()) {
      return (
        <ErrorPage />
      );
    }

    if (this.authenticationRequired()) {
      this.props.redirectAuthentication();
      return null;
    }

    if (!this.isAuthorized()) {
      return <Redirect to="/" />
    }

    const {
      themeComponent,
      component: Component,
      children
    } = this.props;

    const ThemeComponent = themeComponent || React.Fragment;

    return (
      <ThemeComponent>
        {Component && (
          <Component />
        )}

        {children}
      </ThemeComponent>
    );
  }

  render() {
    const { path, exact } = this.props;

    return (
      <Route path={path} exact={exact} render={this.renderRoute} />
    );
  }
}

const withFeatureFlag = featureFlagging(props => ({
  featureFlagOn: props.featureFlag
}));

const withRedux = connect(state => {
  const { security: { loading, authenticated, user, error } } = state;

  return {
    security: {
      loading,
      authenticated,
      user,
      error,
    }
  };
}, {
  redirectAuthentication
});

export default withFeatureFlag(withRedux(StandardRoute));