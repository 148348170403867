class FeatureFlag {

  #key
  #defaultValue

  constructor(key, defaultValue) {
    this.#key = key;
    this.#defaultValue = defaultValue;
  }

  get key() {
    return this.#key;
  }

  get defaultValue() {
    return this.#defaultValue;
  }

}

export default class FeatureFlags {

  static SHOW_PRODUCT_SWITCHER = new FeatureFlag('show-product-switcher', false)
  static REDIRECT_TO_LAUNCH_APP = new FeatureFlag('redirect-to-launch-app', false)

  /**
   * @returns {Array.<FeatureFlag>}
   */
  static all() {
    return [
      FeatureFlags.SHOW_PRODUCT_SWITCHER,
      FeatureFlags.REDIRECT_TO_LAUNCH_APP
    ];
  }
  
}