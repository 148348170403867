import SecurityActionType from 'src/security/SecurityActionType';
import combineRootChildReducers from 'src/util/combineRootChildReducers';
import sessionReducer from 'src/security/SessionReducer';

const initialState = {
  loading: true,
  authenticated: false,
  responseProcessed: false,
  user: null,
  tenant: null,
  availableTenants: [],
  error: false,
  previousState: null
};

function securityRootReducer(state = initialState, action) {
  switch (action.type) {
    case SecurityActionType.PROCESSING_INTERNAL_AUTHENTICATION:
      return {
        ...state,
        ...initialState,
        loading: true
      };

    case SecurityActionType.NOT_AUTHENTICATED:
      return {
        ...state,
        loading: false,
        authenticated: false,
        token: null,
        user: null
      }

    case SecurityActionType.INTERNAL_AUTHENTICATION_PROCESSED: {
      const {user, tenant, availableTenants} = action.payload;
      
      return {
        ...state,
        loading: false,
        authenticated: true,
        user: {
          ...user
        },
        tenant: tenant && {
          ...tenant
        },
        availableTenants: availableTenants
      };
    }

    case SecurityActionType.LOCAL_REFRESH: {
      const {user} = action.payload;

      return {
        ...state,
        user: {
          ...state.user,
          ...user
        }
      };
    }

    case SecurityActionType.PROCESSING_AUTHENTICATION_RESPONSE:
      return {
        ...state,
        responseProcessed: false
      };

    case SecurityActionType.AUTHENTICATION_RESPONSE_PROCESSED: {
      const {previousState} = action.payload;

      return {
        ...state,
        responseProcessed: true,
        previousState
      };
    }

    case SecurityActionType.AUTHENTICATION_ERROR:
      return {
        ...state,
        ...initialState,
        loading: false,
        responseProcessed: true,
        error: true
      };
    
    case SecurityActionType.SWITCH_TENANT_REQUEST: {
      return {
        ...state,
        loading: true
      };
    }

    case SecurityActionType.SWITCH_TENANT_SUCCESS: {
      return {
        ...state,
        loading: false
      };
    }

    case SecurityActionType.SWITCH_TENANT_ERROR: {
      return {
        ...state,
        loading: false,
        error: true
      };
    }

    default:
      return state;
  }
};

export default combineRootChildReducers(securityRootReducer, {
  session: sessionReducer
});
