import React, {useState} from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

function setRef(ref, refValue) {
  if (typeof ref === 'function') {
    ref(refValue);
  } else if (ref) {
    ref.current = refValue;
  }
}

function getContainer(container) {
  if (!container) {
    return document.body;
  }

  if (typeof(container) === 'string') {
    container = document.querySelector(container);
  } else if (typeof(container) === 'function') {
    container = container();
  }

  return ReactDOM.findDOMNode(container) || document.body;
}

const useEnhancedEffect = typeof window !== 'undefined' ? React.useLayoutEffect : React.useEffect;

const Portal = React.forwardRef(function Portal(props, ref) {
  const {container, children} = props;
  const [containerNode, setContainerNode] = useState(null);
  
  useEnhancedEffect(() => {
    setContainerNode(getContainer(props.container))
  }, [container]);
  
  useEnhancedEffect(() => {
    setRef(ref, containerNode);
    
    return () => {
      setRef(ref, null);
    };
  }, [ref, containerNode]);
  
  return containerNode ? ReactDOM.createPortal(children, containerNode) : containerNode;
});

Portal.propTypes = {
  container: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.elementType,
    PropTypes.func
  ]),
  children: PropTypes.node
}

export default Portal;
