import React from 'react';
import PropTypes from 'prop-types';
import {Heading, DetailHeading, Icon} from 'uui_kit';
import Drawer from 'src/components/Drawer';
import Avatar from 'src/components/Avatar';
import Button from 'src/components/Button';
import UserMenuButton from 'src/menu/UserMenuButton';
import UserNavigationMenu from 'src/menu/UserNavigationMenu';

export default class UserDrawerMenu extends React.PureComponent {

  static propTypes = {
    user: PropTypes.shape({
      fullName: PropTypes.string,
      email: PropTypes.string
    }),
    className: PropTypes.string,
    onOpen: PropTypes.func,
    onClose: PropTypes.func
  };

  static defaultProps = {
    user: {},
    onSignOut: () => {},
    onOpen: () => {},
    onClose: () => {}
  }

  handleToggle = isOpen => {
    if (typeof(isOpen) !== 'boolean') {
      isOpen = !this.props.open;
    }

    if (isOpen) {
      this.handleOpen();
    } else {
      this.handleClose();
    }
  }

  handleOpen = () => {
    this.props.onOpen();
  }

  handleClose = () => {
    this.props.onClose();
  }

  render() {
    const {open, user, onSignOut} = this.props;

    return (
      <>
        <UserMenuButton name={user.fullName} onClick={this.handleToggle}/>
        <Drawer
          open={open}
          position="right"
          size="narrow"
          onClose={this.handleClose}
        >
          <div className="mobile-user-menu">
            <header className="user-menu-header">
              <div className="user-avatar">
                <Avatar size="xlarge" person={user.fullName} />
              </div>

              <div className="user-panel">
                <div className="user-info">
                  <Heading level="5" bold>
                    {user.fullName}
                  </Heading>

                  <DetailHeading color="gray" uppercase={false}>
                    {user.email}
                  </DetailHeading>
                </div>

                <Button
                  variant="subtle"
                  className="close-drawer-button asdasdasdasd"
                  onClick={this.handleClose}
                >
                  <Icon icon={['far', 'arrow-right']} size="lg" />
                </Button>
              </div>
            </header>

            <div className="user-menu-body">
              <UserNavigationMenu onItemClick={this.handleClose} />
            </div>

            <footer className="user-menu-footer">
              <Button
                variant="subtle"
                text="Sign out"
                icon={['fas', 'sign-out']}
                onClick={onSignOut}
              />
            </footer>
          </div>
        </Drawer>
      </>
    )
  }
}
