import {getBasePath} from 'src/route/routing';

export function generateState() {
  let {pathname, search} = window.location;
  const basePath = getBasePath();

  if (basePath) {
    pathname = pathname.replace(basePath, '');
  }

  return btoa(JSON.stringify({
    path: pathname,
    search: search
  }));
}

