import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default class Grid extends React.PureComponent {

  static propTypes = {
    container: PropTypes.bool,
    xs: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    sm: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    md: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    lg: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    xl: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    direction: PropTypes.oneOf(['row', 'row-reverse', 'column', 'column-reverse', null]),
    justify: PropTypes.oneOf(['start', 'center', 'end', 'space-between', 'space-around', 'space-evenly', null]),
    align: PropTypes.oneOf(['start', 'center', 'end', 'strech', 'baseline', null]),
    spacing: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, null]),
    className: PropTypes.string,
    style: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    children: PropTypes.node
  };

  static defaultProps = {
    container: false,
    xs: false,
    sm: false,
    md: false,
    lg: false,
    xl: false
  };

  render() {
    const {
      container,
      xs,
      sm,
      md,
      lg,
      xl,
      direction,
      justify,
      align,
      spacing,
      className,
      style,
      children
    } = this.props;

    return (
      <div
        className={classNames(
          container && 'row',
          !container && 'item',
          xs && `col-xs-${xs}`,
          sm && `col-sm-${sm}`,
          md && `col-md-${md}`,
          lg && `col-lg-${lg}`,
          xl && `col-xl-${xl}`,
          direction && `grid-direction-${direction}`,
          justify && `grid-justify-${justify}`,
          align && `grid-align-${align}`,
          spacing && `grid-spacing-${spacing}`,
          className
        )}
        style={style}
      >
        {children}
      </div>
    )
  }
}
