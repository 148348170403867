import config from 'src/config';
import {getSitePath} from 'src/route/routing';

export function getTenantAdminAppUrl(path = '') {
  let tenantAdminAppUrl = new URL(config.tenantAdmin.appUrl);
  
  const sitePath = getSitePath();

  if (sitePath) {
    tenantAdminAppUrl.pathname = `/${sitePath}${tenantAdminAppUrl.pathname}`;
  }

  tenantAdminAppUrl.pathname += path;
  return tenantAdminAppUrl.toString();
}
