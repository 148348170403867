import React from 'react';
import PropTypes from 'prop-types';

export default class Image extends React.Component {

  static propTypes = {
    src: PropTypes.string,
    alt: PropTypes.string,
    className: PropTypes.string,
    PlaceholderComponent: PropTypes.elementType,
    PlaceholderProps: PropTypes.object,
    children: PropTypes.node
  }

  state = {
    notFound: false
  }


  shouldRenderPlaceholder() {
    return !this.props.src || this.state.notFound;
  }

  handleImageNotFound = () => {
    this.setState({
      notFound: true
    });
  }

  renderPlaceholder() {
    const {PlaceholderComponent, PlaceholderProps, children} = this.props;

    if (children) {
      return (
        <>
          {children}
        </>
      );
    }

    if (PlaceholderComponent) {
      return (
        <PlaceholderComponent
          {...PlaceholderProps}
        />
      );
    }

    return null;
  }

  render() {
    const {className, src, alt} = this.props;

    if (this.shouldRenderPlaceholder()) {
      return this.renderPlaceholder();
    }

    return (
      <img
        className={className}
        src={src}
        alt={alt}
        onError={this.handleImageNotFound}
      />
    )
  };
}
