import React from 'react';
import PropTypes from 'prop-types';
import {Icon} from 'uui_kit';
import Avatar from 'src/components/Avatar';

export default class TenantMenuButton extends React.PureComponent {

  static propTypes = {
    name: PropTypes.string,
    disabled: PropTypes.bool,
    onClick: PropTypes.func
  }

  static defaultProps = {
    name: '',
    disabled: false,
    onClick: () => {}
  }

  render() {
    const {name, disabled, onClick} = this.props;

    return (
      <button
        type="button"
        className="tenant-menu-button"
        disabled={disabled}
        onClick={onClick}
      >
        <span className="tenant-avatar-name">
          <Avatar
            name={name}
            size="medium"
            generateColor
          />

          <span className="tenant-name">
            {name}
          </span>
        </span>

        {!disabled && (
          <Icon
            className="tenant-caret-down"
            icon={['fal', 'angle-down']}
            size="2x"
          />
        )}
      </button>
    );
  }
}