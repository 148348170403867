export const LOAD_TENANT_PRODUCTS_REQUEST = 'HOME/LOAD_USER_PRODUCTS_REQUEST';
export const LOAD_TENANT_PRODUCTS_SUCCESS = 'HOME/LOAD_USER_PRODUCTS_SUCCESS';
export const LOAD_TENANT_PRODUCTS_FAIL = 'HOME/LOAD_USER_PRODUCTS_FAIL';

export const LOAD_NOT_LICENSED_PRODUCTS_REQUEST = 'HOME/LOAD_AVAILABLE_PRODUCTS_REQUEST';
export const LOAD_NOT_LICENSED_PRODUCTS_SUCCESS = 'HOME/LOAD_AVAILABLE_PRODUCTS_SUCCESS';
export const LOAD_NOT_LICENSED_PRODUCTS_FAIL = 'HOME/LOAD_AVAILABLE_PRODUCTS_FAIL';

export const RESET = 'HOME/RESET';

export default {
  LOAD_TENANT_PRODUCTS_REQUEST,
  LOAD_TENANT_PRODUCTS_SUCCESS,
  LOAD_TENANT_PRODUCTS_FAIL,
  LOAD_NOT_LICENSED_PRODUCTS_REQUEST,
  LOAD_NOT_LICENSED_PRODUCTS_SUCCESS,
  LOAD_NOT_LICENSED_PRODUCTS_FAIL,
  RESET
};
