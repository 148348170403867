import DeviceActionType from 'src/device/DeviceActionType';

const SCREEN_SIZES = {
  XXL: 1800,
  XL: 1200,
  LG: 992,
  MD: 768,
  SM: 576,
  XS: 0
};

function forEachScreenSize(callback) {
  const screenNames = Object.keys(SCREEN_SIZES);

  screenNames.forEach((sizeName, index) => {
    const nextSizeName = index < (screenNames.length - 1) && screenNames[index + 1];

    callback({
      name: sizeName,
      size: SCREEN_SIZES[sizeName]
    }, nextSizeName && {
      name: nextSizeName,
      size: SCREEN_SIZES[nextSizeName]
    })
  });
}

function getDeviceState() {
  const width = document.body.clientWidth;

  const state = {
    type: {
      mobile: false,
      desktop: false
    },
    size: {
      is: {},
      higherThen: {},
      lowerThen: {}
    }
  };

  forEachScreenSize((current, next) => {
    const sizeName = current.name.toLowerCase();

    const isMinimum = width >= current.size;
    const isMaximum = width < current.size;

    state.size.higherThen[sizeName] = isMinimum;
    state.size.lowerThen[sizeName] = isMaximum;
    state.size.is[sizeName] = next ? width >= current.size && width < next.size : isMinimum;
  });

  state.type.mobile = state.size.lowerThen.md;
  state.type.desktop = state.size.higherThen.md;

  return state;
}

const initialState = getDeviceState();

export default function(state = initialState, action) {
  switch(action.type) {
    case DeviceActionType.CHANGED:
      return getDeviceState();
    
    default:
      return state;
  }
}