import currentUserService from 'src/services/CurrentUserService';
import UserPasswordActionType from 'src/modules/user/password/UserPasswordActionType';

export const changePassword = ({previousPassword, proposedPassword, proposedPasswordConfirmation}) => async dispatch => {
  dispatch({
    type: UserPasswordActionType.CHANGE_PASSWORD_REQUEST
  });

  try {
    await currentUserService.changePassword(previousPassword, proposedPassword, proposedPasswordConfirmation);
    
    dispatch({
      type: UserPasswordActionType.CHANGE_PASSWORD_SUCCESS
    });
  } catch (error) {
    if (!error.isBusiness) {
      console.error(error);
    }

    dispatch({
      type: UserPasswordActionType.CHANGE_PASSWORD_FAIL,
      payload: error.isBusiness ? error.message : null
    });

    throw error;
  }
};

export const reset = () => {
  return {
    type: UserPasswordActionType.RESET
  };
};
