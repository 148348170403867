import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import get from 'lodash/get';
import {Btn, Icon} from 'uui_kit';
import Grid from 'src/components/Grid';
import URLBuilder from 'src/util/URLBuilder';

class LaunchPanel extends React.PureComponent {

  static propTypes = {
    entryPointUrl: PropTypes.string,
    signInUrl: PropTypes.string
  }

  decorateUrl(url) {
    const {utc} = this.props;

    const urlBuilder = new URLBuilder(url);
    urlBuilder.addUrlParam('utc', utc);

    return urlBuilder.toString();
  }

  render() {
    const {entryPointUrl, signInUrl} = this.props;

    return (
      <Grid container className="actions">
        <Grid xs={6} />
  
        <Grid xs={6}>
          {entryPointUrl && (
            <Btn
              href={this.decorateUrl(entryPointUrl)}
              target="_blank"
              newTab
              variant="primary"
              block
            >
              <span>Launch </span>
              <Icon icon={['fal', 'rocket']} />
            </Btn>
          )}
  
          {!entryPointUrl && signInUrl && (
            <Btn
              text="Log In"
              href={this.decorateUrl(signInUrl)}
              variant="product"
              target="_blank"
              newTab
              block
            />
          )}
        </Grid>
      </Grid>
    );
  }
}

const withRedux = connect(state => {
  return {
    utc: get(state, 'security.user.utc')
  };
});

export default withRedux(LaunchPanel);
