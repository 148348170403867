import React from 'react';
import {connect} from 'react-redux';
import Page from 'src/components/Page';
import PermissionsProvider from "src/security/PermissionsProvider";
import SecurityRole from 'src/security/SecurityRole';
import ProductListSection from 'src/components/ProductListSection';
import {loadProducts, reset} from 'src/modules/home/HomeActions';

class Home extends React.PureComponent {

  componentDidMount() {
    this.props.loadProducts();
  }

  componentWillUnmount() {
    this.props.reset();
  }

  render() {
    const { tenant, recommended, available } = this.props.home;

    return (
      <Page title="Home">
        <ProductListSection
          title="My Apps"
          loading={tenant.loading}
          products={tenant.products}
        />

        <PermissionsProvider roles={[SecurityRole.TENANT_ADMIN]}>
          <ProductListSection
            title="Recommended for you"
            loading={recommended.loading}
            products={recommended.products}
            hideIfEmpty
          />
        </PermissionsProvider>

        <PermissionsProvider roles={[SecurityRole.TENANT_ADMIN]}>
          <ProductListSection
            title="Discover"
            loading={available.loading}
            products={available.products}
          />
        </PermissionsProvider>
      </Page>
    )
  }
}

const withRedux = connect(state => {
  const { home } = state.modules;

  return {
    home
  };
}, {
  loadProducts,
  reset
});

export default withRedux(Home);