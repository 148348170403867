import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {Drawer as UiKitDrawer} from 'uui_kit';
import Portal from 'src/components/Portal';

export default class Drawer extends React.PureComponent {

  static propTypes = {
    open: PropTypes.bool,
    position: PropTypes.oneOf([
      'left',
      'right'
    ]),
    size: PropTypes.oneOf([
      'narrow', 
      'medium',
      'wide',
      'large',
      'extended',
      'giant',
      'full'
    ]),
    className: PropTypes.string,
    onClose: PropTypes.func,
    children: PropTypes.node
  }

  render() {
    const {open, position, size, className, onClose, ...props} = this.props;

    return (
      <Portal>
        <div className={classNames('drawer-wrapper', className)}>
          <UiKitDrawer
            {...props}
            isOpen={open}
            placement={position}
            width={size}
            onHide={onClose}
          />
        </div>
      </Portal>
    );
  }
}
