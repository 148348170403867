export const NOT_AUTHENTICATED = 'SECURITY/NOT_AUTHENTICATED';

export const PROCESSING_INTERNAL_AUTHENTICATION = 'SECURITY/PROCESSING_INTERNAL_AUTHENTICATION';
export const INTERNAL_AUTHENTICATION_PROCESSED = 'SECURITY/INTERNAL_AUTHENTICATION_PROCESSED';

export const PROCESSING_AUTHENTICATION_RESPONSE = 'SECURITY/PROCESSING_AUTHENTICATION_RESPONSE';
export const AUTHENTICATION_RESPONSE_PROCESSED = 'SECURITY/AUTHENTICATION_RESPONSE_PROCESSED';
export const AUTHENTICATION_ERROR = 'SECURITY/AUTHENTICATION_ERROR';

export const LOCAL_REFRESH = 'SECURITY/LOCAL_REFRESH';

export const SWITCH_TENANT_REQUEST = 'SECURITY/TENANT/SWITCH_REQUEST';
export const SWITCH_TENANT_SUCCESS = 'SECURITY/TENANT/SWITCH_SUCCESS';
export const SWITCH_TENANT_ERROR = 'SECURITY/TENANT/SWITCH_ERROR';

export const SESSION_CHECK_START = 'SECURITY/SESSION/CHECK_START';
export const SESSION_NEAR_END = 'SECURITY/SESSION/NEAR_END';
export const SESSION_OK = 'SECURITY/SESSION/OK';
export const SESSION_CHECK_FAIL = 'SECURITY/SESSION/FAIL';

export const SESSION_REFRESH_REQUEST = 'SECURITY/SESSION/REFRESH_REQUEST';
export const SESSION_REFRESH_SUCCESS = 'SECURITY/SESSION/REFRESH_SUCCESS';
export const SESSION_REFRESH_ERROR = 'SECURITY/SESSION/REFRESH_ERROR';

export default {
  NOT_AUTHENTICATED,

  PROCESSING_INTERNAL_AUTHENTICATION,
  INTERNAL_AUTHENTICATION_PROCESSED,

  LOCAL_REFRESH,

  PROCESSING_AUTHENTICATION_RESPONSE,
  AUTHENTICATION_RESPONSE_PROCESSED,
  AUTHENTICATION_ERROR,

  SWITCH_TENANT_REQUEST,
  SWITCH_TENANT_SUCCESS,
  SWITCH_TENANT_ERROR,

  SESSION_CHECK_START,
  SESSION_NEAR_END,
  SESSION_OK,
  SESSION_CHECK_FAIL,

  SESSION_REFRESH_REQUEST,
  SESSION_REFRESH_SUCCESS,
  SESSION_REFRESH_ERROR
};