import React from 'react';
import {Switch} from 'react-router-dom';
import DeviceObserver from 'src/device/DeviceObserver';
import SessionObserver from 'src/security/SessionObserver';
import TenantAwareRouter from 'src/route/TenantAwareRouter';
import Route from 'src/route/Route';
import AccessType from 'src/security/AccessType';
import SecurityRole from 'src/security/SecurityRole';
import SecurityAuthentication from 'src/security/SecurityAuthentication';
import SecurityLogout from 'src/security/SecurityLogout';
import Home from 'src/modules/home/Home';
import Licensed from 'src/modules/licensed/Licensed';
import Discover from 'src/modules/discover/Discover';
import Recommended from 'src/modules/recommended/Recommended';
import UserProfile from 'src/modules/user/profile/UserProfile';
import UserPassword from 'src/modules/user/password/UserPassword';
import DeprecatedRoute from 'src/route/DeprecatedRoute';

export default class App extends React.PureComponent {

  render() {
    return (
      <>
        <DeviceObserver />

        <TenantAwareRouter>
          <SessionObserver />
          
          <Switch>
            <Route
              exact
              path="/security/callback"
              access={AccessType.PUBLIC}
              themeComponent={null}
              component={SecurityAuthentication}
            />

            <Route
              exact
              path="/logout"
              access={AccessType.PUBLIC}
              themeComponent={null}
              component={SecurityLogout}
            />

            <DeprecatedRoute
              exact
              path="/"
              component={Home}
              redirectionPath="/home"
            />

            <DeprecatedRoute
              exact
              path="/products/my"
              component={Licensed}
              redirectionPath="/home"
            />

            <DeprecatedRoute
              exact
              path="/products/discover"
              roles={[SecurityRole.TENANT_ADMIN]}
              component={Discover}
              redirectionPath="/explore"
            />

            <DeprecatedRoute
              exact
              path="/products/recommended"
              roles={[SecurityRole.TENANT_ADMIN]}
              component={Recommended}
              redirectionPath="/home"
            />

            <DeprecatedRoute
              exact
              path="/user/profile"
              component={UserProfile}
              redirectionPath="/profile"
            />

            <DeprecatedRoute
              exact
              path="/user/password"
              component={UserPassword}
              redirectionPath="/profile"
            />

            {/* TODO: 404 route */}
          </Switch>
        </TenantAwareRouter>
      </>
    );
  }

}
