export function debounce(originalFunction, wait = 1) {
  let timeout = null;

  return (...args) => {
    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(() => {
      timeout = null;
      originalFunction.apply(this, args);
    }, wait);
  };
}

export default debounce;
