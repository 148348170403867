export default function(rootReducer, childReducers) {
  const mappedChildReducers = Object.keys(childReducers).map(reducerName => {
    return {
      name: reducerName,
      reducer: childReducers[reducerName]
    };
  });

  return (prevState, action) => {
    let newState = rootReducer(prevState, action);
    
    mappedChildReducers.forEach(childReducer => {
      const prevChildState = prevState && prevState[childReducer.name];
      const newChildState = childReducer.reducer(prevChildState, action);

      if (prevChildState === newChildState) {
        return;
      }

      if (prevState === newState) {
        newState = {
          ...prevState,
          [childReducer.name]: newChildState
        };
      } else {
        newState[childReducer.name] = newChildState;
      }
    });

    return newState;
  };
}