import omit from 'lodash/omit';
import BusinessError from 'src/util/BusinessError';
import tenantApi from 'src/services/TenantApi';
import securityService from 'src/services/SecurityService';

export class CurrentUserService {

  async get({throwError = false} = {}) {
    const userInfo = await securityService.getUserInfo( {
      throwError
    });
    
    return omit(userInfo, 'tenants');
  }

  async save(user) {
    try {
      const currentUser = await this.get();

      return await tenantApi.patch(`/users/${currentUser.id}`, {
        firstName: user.firstName,
        lastName: user.lastName,
        phoneNumber: user.phoneNumber
      });
    } catch (error) {
      console.error(error);

      if (error.response && error.response.status === 400) {
        throw new BusinessError('The profile information you have provided is invalid.');
      }
      
      throw error;
    }
  }

  async changePassword(previousPassword, proposedPassword, proposedPasswordConfirmation) {
    const currentUser = await this.get();

    if (!previousPassword) {
      throw new BusinessError('The current password is required.');
    }

    if (!proposedPassword) {
      throw new BusinessError('The new password is required.');
    }

    if (!proposedPasswordConfirmation) {
      throw new BusinessError('The new password confirmation is required.');
    }

    if (proposedPassword !== proposedPasswordConfirmation) {
      throw new BusinessError('The new password and the confirmation do not match.');
    }

    return tenantApi.patch(`/users/${currentUser.id}/password`, {
      previousPassword,
      proposedPassword
    }).catch(error => {
      console.error(error);

      if (error.response && error.response.status === 400) {
        throw new BusinessError('The current password you have provided is invalid.');
      }
      
      throw error;
    });
  }

}

export default new CurrentUserService();
