import React from 'react';
import PropTypes from 'prop-types';
import {Route} from 'react-router-dom';
import ProtectedRoute from 'src/route/Route';
import FeatureFlags from 'src/feature/FeatureFlags';
import useFeatureFlag from 'src/feature/useFeatureFlag';
import config from 'src/config';

function DeprecatedRoute(props) {
  const {exact, path, redirectionPath} = props;
  
  const shouldRedirect = useFeatureFlag(FeatureFlags.REDIRECT_TO_LAUNCH_APP);

  if (shouldRedirect) {
    return (
      <Route
        exact={exact}
        path={path}
        render={() => {
          window.location.href = `${config.launch.appUrl}${redirectionPath}`;
        }}
      />
    );
  }

  return (
    <ProtectedRoute {...props} />
  );
}

DeprecatedRoute.propTypes = {
  ...ProtectedRoute.propTypes,
  redirectionPath: PropTypes.string.isRequired
}

export default DeprecatedRoute;
