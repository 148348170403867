import UserProfileActionType from 'src/modules/user/profile/UserProfileActionType';

const initialState = {
  saving: false,
  success: false,
  error: false,
  errorMessage: null
};

export default function(state = initialState, action) {
  switch(action.type) {
    case UserProfileActionType.SAVE_USER_REQUEST:
      return {
        ...state,
        saving: true,
        success: false,
        error: false,
        errorMessage: null
      };

    case UserProfileActionType.SAVE_USER_SUCCESS:
      return {
        ...state,
        saving: false,
        success: true
      };

    case UserProfileActionType.SAVE_USER_ERROR:
      return {
        ...state,
        saving: false,
        error: true,
        errorMessage: action.payload
      };

    case UserProfileActionType.RESET:
      return {
        ...initialState
      };

    default: return state;
  }
};
