import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import UserDropdownMenu from 'src/menu/UserDropdownMenu';
import UserDrawerMenu from 'src/menu/UserDrawerMenu';
import {logout} from 'src/security/SecurityActions';

class UserMenu extends React.Component {

  static propTypes = {
    className: PropTypes.string,
  };

  state = {
    isMenuOpen: false
  }

  handleOpenMenu = () => {
    this.setState({
      isMenuOpen: true
    });
  }

  handleCloseMenu = () => {
    this.setState({
      isMenuOpen: false
    });
  }

  handleSignOut = () => {
    this.props.logout();
  }

  render() {
    const {user, mobile} = this.props;
    const {isMenuOpen} = this.state;

    const UserMenuComponent = mobile ? UserDrawerMenu : UserDropdownMenu;

    return (
      <UserMenuComponent
        open={isMenuOpen}
        user={user}
        onOpen={this.handleOpenMenu}
        onClose={this.handleCloseMenu}
        onSignOut={this.handleSignOut}
      />
    );
  }

}

const withRedux = connect(state => {
  return {
    user: state.security.user || {},
    mobile: state.device.type.mobile
  };
}, {
  logout
});

export default withRedux(UserMenu);