import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';
import LoadingPage from 'src/components/LoadingPage';
import ErrorPage from 'src/components/ErrorPage';
import {processInternalAuthentication} from 'src/security/SecurityActions';

class SecurityRouter extends React.PureComponent {

  static propTypes = {
    basename: PropTypes.string,
    children: PropTypes.node.isRequired
  }

  componentDidMount() {
    this.props.processInternalAuthentication();
  }

  render() {
    const {security, basename, children} = this.props;

    return (
      <BrowserRouter basename={basename}>
        {security.loading && (
          <LoadingPage />
        )}

        {security.error && (
          <ErrorPage />
        )}

        {!security.loading && !security.error && 
          children
        }
      </BrowserRouter>
    );
  }
}

const withRedux = connect(state => {
  const {security} = state;

  return {
    security: {
      loading: security.loading,
      error: security.error
    }
  };
}, {
  processInternalAuthentication
});

export default withRedux(SecurityRouter);
