import productService from 'src/services/ProductService';
import RecommendedActionType from 'src/modules/recommended/RecommendedActionType';

export const loadProducts = () => async dispatch => {
  dispatch({
    type: RecommendedActionType.LOAD_PRODUCTS_REQUEST
  });

  try {
    const recommendedProducts = await productService.getRecommended();
    
    dispatch({
      type: RecommendedActionType.LOAD_PRODUCTS_SUCCESS,
      payload: recommendedProducts
    });
  } catch (error) {
    console.error(error);

    dispatch({
      type: RecommendedActionType.LOAD_PRODUCTS_FAIL
    });
  }
};

export function reset() {
  return {
    type: RecommendedActionType.RESET
  };
}
