import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'uui_kit/dist/components/atoms/iconography/Icon/Icon';
import Heading from 'uui_kit/dist/components/atoms/Heading/Heading';
import Button from 'src/components/Button';

export default class ErrorPage extends React.PureComponent {

  static propTypes = {
    title: PropTypes.node,
    description: PropTypes.node
  }

  static defaultProps = {
    title: 'Something went wrong',
    description: 'Sorry, but an unexpected error happened while processing your request.'
  }

  handleRefresh = () => {
    window.location.reload();
  }

  render() {
    const {title, description} = this.props;

    return (
      <div className="page-error">
        <div className="error-panel">
          <Icon icon={['fas', 'times-octagon']} size="8x" />

          <Heading
            level="2"
            color="error"
            bold
          >
            {title}
          </Heading>

          <Heading level="5" color="gray" uppercase={false}>
            {description}
          </Heading>

          <Button
            className="retry-button"
            variant="quin-darker"
            text="Try again"
            icon={['fas', 'redo']}
            onClick={this.handleRefresh}
          />
        </div>
      </div>
    );
  }
  
}
