import React from 'react'
import {NavWrap} from 'uui_kit/dist/components/layout/SideNav';
import MenuSection from 'src/menu/MenuSection';
import menus from 'src/menu/menu.js';

export default class NavigationMenu extends React.PureComponent {
  render() {
    return (
      <NavWrap className="navigation-menu">
        {menus.map(menu => (
          <MenuSection
            key={menu.title || ''}
            title={menu.title}
            items={menu.items}
          />
        ))}
      </NavWrap>
    )
  }
}