import React from 'react';
import PropTypes from 'prop-types';
import {CSSTransition} from 'react-transition-group';

const transtionNamesType = PropTypes.oneOf([
  'fadein',
  'fadeup',
  'fadedown',
  'faderight',
  'fadeleft'
]);

export default class SimpleTransition extends React.PureComponent {

  static propTypes = {
    in: PropTypes.bool,
    mountOnEnter: PropTypes.bool,
    unmountOnExit: PropTypes.bool,
    order: PropTypes.number,
    name: PropTypes.oneOfType([
      transtionNamesType,
      PropTypes.shape({
        appear: transtionNamesType,
        enter: transtionNamesType,
        exit: transtionNamesType
      })
    ]).isRequired,
    timeout: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.shape({
        appear: PropTypes.number,
        enter: PropTypes.number,
        exit: PropTypes.number
      })
    ]),
    children: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.func
    ])
  };

  static defaultProps = {
    in: true,
    mountOnEnter: false,
    unmountOnExit: true,
    order: null,
    timeout: 1000
  }

  render() {
    const {name, order, children, ...rest} = this.props;

    const style = {
      ...children.props.style
    };

    if (order) {
      style.transitionDelay = `${(order * 100)}ms`;
    }

    return (
      <CSSTransition classNames={`transition-container-${name}`} {...rest}>
        {React.cloneElement(children, {style})}
      </CSSTransition>
    );
  }
}
