import React from 'react';
import PropTypes from 'prop-types';
import TextInput from 'src/components/TextInput';
import equalsDeep from 'src/util/equalsDeep';
import debounce from 'src/util/debounce';

const initialState = {
  value: '',
  touched: false,
  errorTouched: false,
  invalidMessage: null
};

export default class PasswordInput extends React.Component {

  static propTypes = {
    validations: PropTypes.shape({
      required: PropTypes.bool,
      minimum: PropTypes.number,
      equalTo: PropTypes.string
    }),
    name: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
    errorMessage: PropTypes.string,
    validate: PropTypes.func,
    onChange: PropTypes.func
  }

  static defaultProps = {
    label: '',
    validations: {},
    validate: () => {},
    onChange: () => {}
  }

  state = {
    ...initialState
  }

  componentDidMount() {
    this.setState({
      ...initialState
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (equalsDeep(prevProps.validations, this.props.validations)) {
      return;
    }

    this.handleValidation();
  }

  handlePasswordTyped = ({target}) => {
    this.setState({
      touched: true,
      value: target.value
    }, () => {
      this.handleValidation();
    });
  }

  handleBlur = () => {
    this.handleValidation(true);
  }

  handleValidation = debounce(async immediate => {
    const {label} = this.props;
    const {touched, value: password, errorTouched} = this.state;

    if (!touched) {
      this.props.onChange({
        touched: false,
        valid: false,
        password
      });

      return;
    }

    let errorMessage = this.performValidation();

    if (!errorMessage) {
      errorMessage = await this.props.validate({
        label,
        password
      });
    }

    this.props.onChange({
      touched,
      valid: !errorMessage,
      password
    });
    
    if (immediate || errorTouched) {
      this.setValidationState(errorMessage);
    } else {
      this.setDebouncedValidationState(errorMessage);
    }
  })

  performValidation = () => {
    const {validations} = this.props;
    const {value: password} = this.state;

    let label = this.props.label.toLowerCase();

    if (validations.required && !password) {
      return `The ${label} is required.`;
    }

    if (validations.minimum && password.length < validations.minimum) {
      return `The ${label} must be at least 6 characters long.`;
    }

    if (validations.equalTo && password !== validations.equalTo) {
      return 'The passwords do not match.';
    }
  }

  setDebouncedValidationState = debounce(errorMessage => {
    this.setValidationState(errorMessage);
  }, 500)

  setValidationState(errorMessage) {
    const {errorTouched} = this.state;

    this.setState({
      errorTouched: errorTouched || !!errorMessage,
      invalidMessage: errorMessage
    });
  }

  render() {
    const {name, label, disabled, errorMessage, ...props} = this.props;
    const {value, invalidMessage} = this.state;

    return (
      <TextInput
        {...props}
        type="password"
        name={name}
        label={label}
        value={value}
        disabled={disabled}
        error={invalidMessage || errorMessage}
        onChange={this.handlePasswordTyped}
        onBlur={this.handleBlur}
      />
    );
  }

  static getDerivedStateFromProps(props, state) {
    const newState = {};

    if (state.valueFromProps !== props.value) {
      newState.valueFromProps = props.value;
      newState.value = props.value;
    }

    if (state.touchedFromProps !== props.touched) {
      newState.touchedFromProps = props.touched;
      newState.touched = Boolean(props.touched);
    }

    if (newState.touched === false) {
      newState.errorTouched = false;
      newState.invalidMessage = null;
    }

    return newState;
  }

}