import UserProfileActionType from 'src/modules/user/profile/UserProfileActionType';
import currentUserService from 'src/services/CurrentUserService';
import {refreshLocalData} from 'src/security/SecurityActions';

export const saveUser = user => async dispatch => {
  dispatch({
    type: UserProfileActionType.SAVE_USER_REQUEST
  });

  try {
    await currentUserService.save(user);
    
    dispatch({
      type: UserProfileActionType.SAVE_USER_SUCCESS
    });

    dispatch(refreshLocalData({user}));
  } catch (error) {
    if (!error.isBusiness) {
      console.error(error);
    }

    dispatch({
      type: UserProfileActionType.SAVE_USER_ERROR,
      payload: error.isBusiness ? error.message : null
    });
  }
};

export const reset = () => {
  return {
    type: UserProfileActionType.RESET
  };
};
